import axios from 'axios';

export class BannersService {
    async getDeals(page = 0, limit = 10000, type = 'Deal') {
        return (await axios.get('/event-deal?page=' + page + '&type='+ type + '&limit=' + limit + '')).data;
    }

    async fetchAll(page = 0, limit = 20) {
        return (await axios.get('/banner?page='  + page + '&limit=' + limit)).data;
    }

    async fetchOne(id) {
        return (await axios.get('/banner/' + id)).data;
    }

    async create(item) {
        return (await axios.post('/banner', item)).data;
    }

    async delete(item) {
        return (await axios.delete('/banner/' + item._id)).data;
    }
}