<template>
  <div>
    <v-tabs v-model="tab" grow>
      <v-tab>All</v-tab>
      <v-tab>Pending</v-tab>
      <v-tab>Approved</v-tab>
      <v-tab>Rejected</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="elevation-1">
      <v-tab-item v-for="(item,i) in tabs" :key="i">
        <DataTable
            :title="item.title"
            :headers="headers"
            :loader="load"
            ref="datatable"
            :allow-add="false"
            :show-actions="checkScope(['claimed-businesses:edit', 'claimed-businesses:detail', 'claimed-businesses:delete'])"
            :edit-handler="checkScope(['claimed-businesses:edit']) ? edit : null"
            :view-handler="checkScope(['claimed-businesses:detail']) ? view : null"
            :delete-handler="checkScope(['claimed-businesses:delete']) ? service.delete : null"
        >
          <template #user="{item}">
            {{ item.userId.name }}
          </template>
          <template #business="{item}">
            {{ item && item.businessId ? item.businessId.name : 'N/A'}}
          </template>

          <template #description="{item}">
            {{ item && item.description ? item.description : '...'}}
          </template>

          <template #documents="{item}">
            <v-btn v-if="item.files && item.files.length > 0" @click="viewDocuments(item.files)" color="primary"
                   outlined small>View
            </v-btn>
            <span v-else>No Documents</span>
          </template>
          <template #extra-actions="{item}">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-if="(item.status === 'Pending' || item.status === 'Rejected') && tab !== 0 && checkScope(['claimed-businesses:status'])"
                    @click="updateStatus(item, 'Approved')"
                    color="white"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1 my-1"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-if="(item.status === 'Pending' || item.status === 'Approved') && tab !== 0 && checkScope(['claimed-businesses:status'])"
                    @click="updateStatus(item, 'Rejected')"
                    color="white"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1 my-1"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>Reject</span>
            </v-tooltip>
          </template>
        </DataTable>
      </v-tab-item>
    </v-tabs-items>
    <image-viewer ref="documentViewer"/>
    <confirm-dialogue ref="confirm"/>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {BusinessClaimService} from "../../services/business-claim";
import {checkScope} from "../../utils/local";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      tab: null,
      headers: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Business',
          value: 'business',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Status',
          value: 'status',
        },
        {
          text: 'Documents',
          value: 'documents'
        },
      ],
      tabs: [
        {
          title: 'All Claimed Business',
          status: null,
        },
        {
          title: 'Pending Claimed Business',
          status: 'Pending'
        },
        {
          title: 'Approved Claimed Business',
          status: 'Approved'
        },
        {
          title: 'Rejected Claimed Business',
          status: 'Rejected'
        }
      ],
      service: new BusinessClaimService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search, this.tabs[this.tab].status)
    },
    view(item) {
      let routeData = this.$router.resolve('/business?id=' + item.businessId._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/business?id=' + item.businessId._id + '&view=true')
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'Business', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push('/business?id=' + item.businessId._id)
    },
    viewDocuments(documents) {
      this.$refs.documentViewer.show({images: documents})
    },
    async updateStatus(item, status) {
      const confirm = await this.$refs.confirm.show({
        title: 'Warning',
        message: `This business claim request will be ${status}. Do you want to proceed?`,
        okButton: 'Yes',
        cancelButton: 'No'
      });
      if (confirm) {
        const data = {
          status
        }
        try {
          this.$refs.datatable[0].loading = true
          await this.service.update(item._id, data)
          this.$refs.datatable[0].loading = false
          this.refresh()
        } catch (e) {
          this.$refs.datatable.loading = false
        }
      }
    },
    refresh() {
      const refresh = document.getElementsByClassName('refresh')
      for (let i = 0; i < refresh.length; i++) {
        refresh[i].click()
      }
    }
  }
}
</script>

<style scoped>

</style>