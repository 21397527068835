<template>
  <div>
    <DataTable
        title="Reviews"
        :headers="headers"
        :loader="load"
        :allow-add="false"
        :show-actions="checkScope(['reviews:edit', 'reviews:detail', 'reviews:delete'])"
        :edit-handler="checkScope(['reviews:edit']) ? edit : null"
        :view-handler="checkScope(['reviews:detail']) ? view : null"
        :delete-handler="checkScope(['reviews:delete']) ? service.delete : null"
    >
      <template #createdAt="{item}">
        {{ item.createdAt | date('MM-DD-YYYY hh:mm a') }}
      </template>
      <template #reply="{item}">
        {{ item.reply && item.reply.replyText }}
      </template>
      <template #user="{item}">
        {{ item.userId ? item.userId.name : '-' }}
      </template>
      <template #business="{item}">
        {{ item.businessId ? item.businessId.name : '-' }}
      </template>
      <template #rating="{item}">
        <v-rating :value="item.rating" dense color="amber" background-color="amber" readonly/>
      </template>

    </DataTable>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {BusinessReviewsService} from "../../services/business-reviews";
import {checkScope} from "../../utils/local";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      headers: [
        {
          text: 'User',
          value: 'user',
        },
        {
          text: 'Business',
          value: 'business',
        },
        {
          text: 'Rating',
          value: 'rating',
        },
        {
          text: 'Review',
          value: 'review'
        },
        {
          text: 'Owner Reply',
          value: 'reply'
        },
      ],
      service: new BusinessReviewsService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search)
    },
    view(item) {
      let routeData = this.$router.resolve('/review?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/review?id=' + item._id + '&view=true')
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'Review', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push('/review?id=' + item._id)
    }
  }
}
</script>

<style scoped>

</style>