import axios from 'axios';

export class PersonService {
    async fetchAll(page = 0, limit = 20, search = '', loginVia = null, businessUsers = null, fromDate = null, toDate = null, sortBy = null) {
        return (await axios.get('/persons/users?page=' + page + '&limit=' + limit + '&search=' + search + (sortBy ? '&sortBy=' + sortBy : '') + (loginVia ? '&loginVia=' + loginVia : '') + (businessUsers !== null ? '&businessUsers=' + businessUsers : '') + (fromDate !== null ? '&fromDate=' + fromDate : '') + (toDate !== null ? '&toDate=' + toDate : ''))).data;
    }

    async fetchAllAdmins(page = 0, limit = 20, search = '') {
        const user = JSON.parse(localStorage.getItem('auth_user'))
        return (await axios.get('/persons/admins?page=' + page + '&limit=' + limit + '&search=' + search))
            .data.filter((o) => o._id !== user._id);
    }

    async fetchOne(id) {
        return (await axios.get('/persons/' + id)).data;
    }

    async update(id, item) {
        return (await axios.patch('/persons/' + id, item)).data;
    }

    async delete(item) {
        return (await axios.delete('/persons/' + item._id)).data;
    }
}