<template>
  <div>
    <DataTable
        title="Categories"
        :headers="headers"
        :loader="load"
        :allow-add="false"
        :show-actions="checkScope(['categories:detail', 'categories:edit'])"
        :view-handler="checkScope(['categories:detail']) ? view : null"
        :edit-handler="checkScope(['categories:edit']) ? edit : null"
    >
      <template #createdAt="{item}">
        {{ item.createdAt | date('MM-DD-YYYY hh:mm a') }}
      </template>
      <template #updatedAt="{item}">
        {{ item.updatedAt | date('MM-DD-YYYY hh:mm a') }}
      </template>
    </DataTable>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {CategoryService} from "../../services/category";
import {checkScope} from "../../utils/local";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Created',
          value: 'createdAt'
        },
        {
          text: 'Updated',
          value: 'updatedAt'
        },
      ],
      service: new CategoryService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search)
    },
    view(item) {
      let routeData = this.$router.resolve('/category?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/category?id=' + item._id + '&view=true')
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'Category', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push('/category?id=' + item._id)
    }
  }
}
</script>

<style scoped>

</style>