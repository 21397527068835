<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView" @done="loadReview">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update Review' : 'Add New Review' }}</p>
    <p v-else class="span-2 form__title">Review Detail</p>
    {{ review.replay }}

    <v-text-field
        v-model="review.userId.name"
        dense
        readonly
        :rules="[required('A User must be provided')]"
        label="User Name"
        outlined
        class="span-2"
    />

    <v-text-field
        v-model="review.businessId.name"
        dense
        readonly
        :rules="[required('A Business must be provided')]"
        label="Business Name"
        outlined
        class="span-2"
    />

    <div class="mb-4">
      <p class="mb-1">Rating</p>
      <v-rating v-model="review.rating" color="amber" background-color="amber" dense hover :readonly="isView"/>
    </div>

    <v-textarea
        v-model="review.review"
        dense
        :readonly="isView"
        :rules="[required('An review description must be provided')]"
        label="Review"
        outlined
        class="span-2"
    />

    <v-text-field
        v-if="review && review.reply && review.reply.replyText"
        v-model="review.reply.replyText"
        dense
        :rules="[required('A Replay Text must be provided')]"
        label="Replay Text"
        outlined
        class="span-2"
    />

    <div v-if="review.image && review.image.length > 0">
      <Carousal :images="review.image" />
    </div>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {required} from '@/utils/validators';
import {BusinessReviewsService} from "@/services/business-reviews";
import Carousal from "@/components/Carousal";

export default {
  name: 'Form',
  components: {Carousal, SimpleForm},

  data: () => ({
        isEdit: false,
        isView: false,
        loading: false,
        review: {
          userId: {
            name: undefined
          },
          businessId: {
            name: undefined
          },
          reply: {
            replyText: ''
          },
          rating: 1,
          review: undefined
        },
        errors: [],
        service: new BusinessReviewsService()
      }
  ),

  mounted() {
    this.loadReview();
  },
  methods: {
    required,
    async loadReview() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.isView = !!this.$route.query.view
      this.loading = true;
      this.review = await this.service.fetchOne(this.$route.query.id)
      if (!this.review.userId) {
        this.review.userId = {
          name: 'No User'
        }
      }
      if (!this.review.businessId) {
        this.review.businessId = {
          name: 'No Business'
        }
      }
      this.loading = false;
    },
    preCheck(context) {
      let error = false
      if (!context.validate()) {
        error = true
      }
      return !error;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          try {
            this.$refs.form.showLoading(true, 'Updating review...')
            const data = {
              rating: this.review.rating,
              review: this.review.review,
              reply: {
                replyText: this.review.reply.replyText
              }
            }
            await this.service.update(this.review._id, data)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Review updated successfully')
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating review',
              message: e.toString()
            });
            return false
          }
        }
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>