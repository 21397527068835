<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update User' : 'Add New User' }}</p>
    <p v-else class="span-2 form__title">User Detail</p>
    <div v-if="user.image" class="d-flex justify-center align-center flex-column span-2 mb-4">
      <v-avatar size="180" color="primary" @click="previewImage" class="pointer">
        <img :src="user.image | image"/>
      </v-avatar>
    </div>
    <v-text-field
        v-model="user.name"
        dense
        :readonly="isView"
        label="Name"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="user.email"
        dense
        :readonly="isView"
        label="Email"
        outlined
        class="span-2"
    />
    <div v-if="!isView" class="mb-3 span-2">
      <phoneNumberField
          v-model="contact"
          class="span-2"
          show-code-on-list
          :size="$vuetify.breakpoint.xs ? '' : 'lg'"
          :error="contactError"
          color="#ee6725"
          valid-color="#ee6725"
          error-color="red"
          :default-country-code="this.user.countryCode"
          @update="validatePhone"
      />
      <p :class="`text-caption ${contactError && this.showPhoneError ? 'error--text' : 'white--text'} ma-0`">Valid Phone
        is required</p>
    </div>
    <v-text-field
        v-else
        v-model="user.contact"
        :readonly="isView"
        dense
        label="Phone Number"
        outlined
        class="span-2"
    />

    <v-text-field
        v-model="user.dob"
        dense
        :readonly="isView"
        label="Date of birth"
        outlined
        class="span-2"
        type="date"
    />

    <v-select
        v-model="user.gender"
        dense
        outlined
        :readonly="isView"
        label="Gender"
        :items="genders"
        class="span-2"
    >
    </v-select>


    <v-text-field
        v-model="user.address"
        dense
        :readonly="isView"
        label="Address"
        outlined
        class="span-2"
    />

    <v-row class="span-2 pa-0" :no-gutters="$vuetify.breakpoint.smAndDown" dense>
      <v-col cols="12" md="8">
        <v-text-field
            v-model="user.city"
            dense
            :readonly="isView"
            label="City"
            outlined
            class="span-2"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            v-model="user.postalCode"
            dense
            :readonly="isView"
            label="Postal Code"
            outlined
            class="span-2"
        />
      </v-col>
    </v-row>
    <imageViewer ref="imageViewer"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import parsePhoneNumber from "libphonenumber-js";
import {PersonService} from "@/services/person";

export default {
  name: 'Form',
  components: {SimpleForm},

  data: () => ({
    isEdit: false,
    isView: true,
    loading: false,
    user: {
      name: undefined,
      email: undefined,
      dob: undefined,
      contact: undefined,
      gender: undefined,
      authMethod: undefined,
      status: 1,
      countryCode: 'US'
    },
    genders: ['Male', 'Female', 'Unspecified'],
    errors: [],
    contact: undefined,
    contactError: false,
    showPhoneError: false,
    images: [],
    imagesToBeDeleted: [],
    service: new PersonService()
  }),

  mounted() {
    this.loadUser();
  },

  methods: {
    validatePhone(event) {
      this.user.contact = event.formatInternational;
      this.contactError = !(this.user.contact && event.isValid);
    },
    async loadUser() {
      try {
        if (!this.$route.query.id) return;
        this.isEdit = true;
        this.$refs.form.showLoading(true, 'Loading User Data...')
        this.user = await this.service.fetchOne(this.$route.query.id)
        const contact = this.user.contact ? parsePhoneNumber(this.user.contact) : undefined
        console.log(contact)
        if (contact) {
          this.user.countryCode = contact.country
          this.contact = contact.nationalNumber
        } else {
          this.contact = this.user.contact
        }
        this.user.dob = this.$options.filters.date(this.user.dob, 'YYYY-MM-DD')
        this.$refs.form.showLoading(false, '')
      } catch (e) {
        this.$refs.form.showLoading(false, '')
        this.$refs.form.reportError({
          title: 'Error getting user info',
          message: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occured. Try again later.',
        })
      }
    },
    preCheck(context) {
      this.showPhoneError = false
      let error = false
      if (!context.validate()) {
        error = true
      }
      if (!this.user.contact || this.contactError) {
        this.contactError = true
        this.showPhoneError = true
        error = true
      }
      return !error;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          try {
            console.log(this.user)
            // return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating user',
              message: e.toString()
            });
            return false
          }
        } else {
          try {
            console.log(this.user)
            // return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating user',
              description: e.toString(),
              okButton: 'OK'
            });
            return false
          }
        }
      }
    },
    previewImage() {
      this.$refs.imageViewer.show({images: [this.user.image]})
    }
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>