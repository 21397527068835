var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("All")]),_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v("Approved")]),_c('v-tab',[_vm._v("Rejected")])],1),_c('v-tabs-items',{staticClass:"elevation-1",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,i){return _c('v-tab-item',{key:i},[_c('DataTable',{ref:"datatable",refInFor:true,attrs:{"title":item.title,"headers":_vm.headers,"loader":_vm.load,"allow-add":false,"show-actions":_vm.checkScope(['claimed-businesses:edit', 'claimed-businesses:detail', 'claimed-businesses:delete']),"edit-handler":_vm.checkScope(['claimed-businesses:edit']) ? _vm.edit : null,"view-handler":_vm.checkScope(['claimed-businesses:detail']) ? _vm.view : null,"delete-handler":_vm.checkScope(['claimed-businesses:delete']) ? _vm.service.delete : null},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userId.name)+" ")]}},{key:"business",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.businessId ? item.businessId.name : 'N/A')+" ")]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item && item.description ? item.description : '...')+" ")]}},{key:"documents",fn:function(ref){
var item = ref.item;
return [(item.files && item.files.length > 0)?_c('v-btn',{attrs:{"color":"primary","outlined":"","small":""},on:{"click":function($event){return _vm.viewDocuments(item.files)}}},[_vm._v("View ")]):_c('span',[_vm._v("No Documents")])]}},{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((item.status === 'Pending' || item.status === 'Rejected') && _vm.tab !== 0 && _vm.checkScope(['claimed-businesses:status']))?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1 my-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.updateStatus(item, 'Approved')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((item.status === 'Pending' || item.status === 'Approved') && _vm.tab !== 0 && _vm.checkScope(['claimed-businesses:status']))?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1 my-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.updateStatus(item, 'Rejected')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject")])])]}}],null,true)})],1)}),1),_c('image-viewer',{ref:"documentViewer"}),_c('confirm-dialogue',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }