<template>
  <div>
    <v-row>
      <v-col cols="12" md="6">
        <v-card class="pa-2">
          <v-chart v-if="!loadingPie" class="chart" :option="pieOptions"/>
          <div v-else style="height: 450px" class="d-flex justify-center align-center">
            <v-progress-circular indeterminate/>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card class="pa-2">
          <v-chart v-if="!loadingBar" class="chart" :option="barOptions"/>
          <div v-else style="height: 450px" class="d-flex justify-center align-center">
            <v-progress-circular indeterminate/>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {DashboardService} from "@/services/dashboard";

export default {
  name: "Home",
  data() {
    return {
      pieOptions: {
        title: {
          text: "Registered Users",
          left: "center"
        },
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b} : {c} ({d}%)"
        },
        legend: {
          orient: "vertical",
          left: "left",
          data: [
            "Gmail",
            "Email",
            "Facebook",
            "FBNY",
          ]
        },
        series: [
          {
            name: "Registered Users",
            type: "pie",
            radius: "55%",
            center: ["50%", "50%"],
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)"
              }
            }
          }
        ]
      },
      barOptions: {
        title: {
          text: "Business Per City",
          left: "center",
          link: '/businesses',
          target: 'self'
        },
        grid: {
          bottom: '5%',
          containLabel: true
        },
        tooltip: {
          trigger: 'item',
          formatter: "{a} <br/>{b} : {c}"
        },
        xAxis: [
          {
            type: 'category',
            data: [],
            name: "Cities",
            axisLabel: {
              interval: 0,
              rotate: 45
            },
            nameLocation: "middle",
            nameGap: 60,
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: "Businesses",
            nameLocation: "middle",
            nameGap: 50,
            nameTextStyle: {
              fontWeight: "bold",
              fontSize: 16
            }
          }
        ],
        series: [
          {
            name: 'Businesses',
            type: 'bar',
            barWidth: '50%',
            data: [],
            label: {
              rotate: 90,
              show: true,
            },
          }
        ]
      },
      service: new DashboardService(),
      loadingPie: true,
      loadingBar: true
    };
  },
  mounted() {
    this.getChartData()
  },
  methods: {
    async getChartData() {
      this.loadingPie = true
      this.loadingBar = true
      const personsSummary = await this.service.summaryPerson()
      const legendDataPie = []
      const dataPie = []
      Object.keys(personsSummary).forEach((key) => {
        legendDataPie.push(key)
        dataPie.push({value: personsSummary[key], name: key})
      })
      this.pieOptions.series[0].data = dataPie
      this.pieOptions.legend.data = legendDataPie
      this.loadingPie = false
      const businessSummary = await this.service.summaryBusiness()
      const legendDataBar = []
      const dataBar = []
      Object.keys(businessSummary).forEach((key) => {
        legendDataBar.push(this.$options.filters.capitalize(key))
        dataBar.push(businessSummary[key])
      })
      this.barOptions.xAxis[0].data = legendDataBar
      this.barOptions.series[0].data = dataBar
      this.loadingBar = false
    },
  }
};
</script>

<style scoped>
.chart {
  height: 450px;
  width: 100%;
}
</style>