<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView" @done="clearForm">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update Deal' : 'Add New Deal' }}</p>
    <p v-else class="span-2 form__title">Deal Detail</p>

    <v-text-field
        v-model="deal.title"
        dense
        :readonly="isView"
        :rules="[required('A deal title must be provided')]"
        label="Title"
        outlined
        class="span-2"
    />

    <v-textarea
        v-model="deal.description"
        dense
        :readonly="isView"
        :rules="[required('An deal description must be provided')]"
        label="Description"
        outlined
        class="span-2"
    />

    <div v-if="!isView" class="mb-3 span-2">
      <phoneNumberField
          v-model="phone"
          class="span-2"
          show-code-on-list
          :size="$vuetify.breakpoint.xs ? '' : 'lg'"
          :error="phoneError"
          color="#ee6725"
          valid-color="#ee6725"
          error-color="red"
          :default-country-code="this.deal.countryCode"
          @update="validatePhone"
      />
      <p :class="`text-caption ${phoneError && this.showPhoneError ? 'error--text' : 'white--text'} ma-0`">Valid Phone
        is required</p>
    </div>

    <v-text-field
        v-else
        v-model="deal.contact"
        :readonly="isView"
        dense
        :rules="[required('A business name must be provided')]"
        label="Phone Number"
        outlined
        class="span-2"
    />

    <v-row class="span-2" :no-gutters="$vuetify.breakpoint.smAndDown" dense>
      <v-col cols="12" md="6">
        <v-text-field
            v-model="deal.startDateTime"
            dense
            :readonly="isView"
            :rules="[required()]"
            label="Start Date Time"
            outlined
            type="datetime-local"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
            v-model="deal.endDateTime"
            dense
            :readonly="isView"
            :rules="[required()]"
            label="End Date"
            outlined
            type="datetime-local"
        />
      </v-col>
    </v-row>

    <v-text-field
        v-model="deal.ticketUrl"
        dense
        :readonly="isView"
        :rules="[required('A ticket purchase url must be provided'),url()]"
        label="Ticket Purchase URL"
        outlined
        class="span-2"
    />

    <v-row class="span-2">
      <v-col cols="12" md="8">
        <GmapAutocomplete :value="deal.address" :readonly="isView" @place_changed="setPlace" @keyup.enter="setPlace"
                          clearable
                          placeholder="Address"/>
      </v-col>
      <v-col cols="12" md="4">
        <v-text-field
            v-model="location"
            readonly
            dense
            label="Lng/Lat"
            outlined
        />
      </v-col>
    </v-row>

    <div class="span-2">
      <GmapMap
          :center="center"
          :zoom="18"
          map-style-id="roadmap"
          :options="mapOptions"
          style="width: 100%; height: 50vmin"
          ref="mapRef"
          @click="handleMapClick"
      >
        <GmapMarker
            :position="marker.position"
            :clickable="true"
            :draggable="true"
            @drag="handleMarkerDrag"
            @click="panToMarker"
        />
      </GmapMap>
    </div>

    <v-divider class="span-2" style="margin-bottom: 20px"/>

    <div class="mb-3 span-2">
      <h3>Media</h3>

      <div v-if="isView || isEdit" class="mt-2 span-2" style="height: 500px">
        <Carousal :images="this.deal.images" class="span-2"/>
      </div>

      <div v-if="!isView && uploadedMediaFiles && uploadedMediaFiles.length > 0" class="my-6">
        <div class="mb-3 span-2">
          <h3>Uploaded Files</h3>
          <v-row class="mt-2 span-2">
            <v-col v-for="(mediaItem, i) in uploadedMediaFiles" :key="i"
                   :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                   class="file-display ma-2  pa-0"
                   cols="3"
                   no-gutters
                   style="position: relative;max-width: 130px !important;"
            >
              <div class="preview-image">
                <img :src="mediaItem.url" height="100%"
                     width="100%"/>
                <v-icon class="remove-icon" color="error" @click="removeUploadedFiles(mediaItem.fileName, i)">mdi-delete
                </v-icon>
              </div>
            </v-col>
          </v-row>
        </div>
      </div>

      <div v-if="!isView" class="mediaPrickerWrapper py-8 d-flex justify-center align-center my-5 flex-column"
           style="position: relative"
           @click="pickFile"
           @drop="dropHandler($event)"
           @dragenter.prevent
           @dragover.prevent
      >
        <v-avatar color="grey lighten-3 mb-2">
          <v-icon>mdi-upload</v-icon>
        </v-avatar>
        <p class="ma-0 text-center">Drag images here<br>
          <span class="font-weight-bold primary--text">Or select images to upload </span></p>
        <v-file-input
            id="mediaPicker"
            v-model="selectedMedia"
            :value="selectedMedia"
            accept="image/*, video/*"
            class="d-none"
            multiple
            @change="pickHandler"
        />
      </div>

      <div v-if="media.length > 0">
        <v-row class="span-2">
          <v-col v-for="(mediaItem, i) in media" :key="i"
                 :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                 class="file-display ma-2  pa-0"
                 cols="3"
                 no-gutters
                 style="position: relative;max-width: 130px !important;"
          >
            <div class="preview-image">
              <img :src="mediaItem.url" height="100%"
                   width="100%"/>
              <v-icon class="remove-icon" color="error" @click="removeMedia(mediaItem)">mdi-delete</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>

    </div>

  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {required, url} from '../../utils/validators';
import parsePhoneNumber from "libphonenumber-js";
import Carousal from "../../components/Carousal";
import {EventDealService} from "../../services/event-deal";
import moment from "moment";
import axios from "axios";

async function uploadWithMessage(context, list, message) {
  context.showLoading(true, message)
  const newList = [];
  for (const item of list) {
    let formData = new FormData()
    formData.append('image', item.file);
    const response = await axios.post('/save-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }
    )
    newList.push(response.data.name)
    context.showLoading(true, message)
  }
  return newList;
}

export default {
  name: 'Form',
  components: {Carousal, SimpleForm},

  data: () => ({
    isEdit: false,
    isView: false,
    loading: false,
    deal: {
      title: undefined,
      description: undefined,
      contact: undefined,
      countryCode: 'PK',
      startDateTime: undefined,
      endDateTime: undefined,
      ticketUrl: undefined,
      location: {
        type: 'Points',
        coordinates: []
      },
      address: undefined,
      images: [],
      status: 'Approved',
      type: 'Deal'
    },
    location: '',
    errors: [],
    phone: undefined,
    phoneError: false,
    showPhoneError: false,
    service: new EventDealService(),
    marker: {position: {lng: 10, lat: 10}},
    center: {lng: 10, lat: 10},
    mapOptions: {
      disableDefaultUI: true,
    },
    currentPlace: null,
    canGetAddress: true,

    // media picker
    media: [],
    mediaErrors: [],
    selectedMedia: [],
    uploadedMediaFiles: [],
    filesToBeDeleted: [],
    uploadedFiles: 0,
  }),

  async mounted() {
    await this.loadDeal();
    if (!(this.deal.location && this.deal.location.coordinates && this.deal.location.coordinates.length > 0)) {
      this.geoLocate();
    } else {
      this.marker.position = {
        lng: this.deal.location.coordinates[0],
        lat: this.deal.location.coordinates[1]
      }
      this.panToMarker()
    }
  },

  methods: {
    required,
    validatePhone(event) {
      this.deal.contact = event.formatInternational;
      this.phoneError = !(this.deal.contact && event.isValid);
    },
    url,
    async setPlace(place) {
      let location = null
      let address = ''
      if (place && place.place_id) {
        location = place
        address = place.name + ' ' + place.formatted_address
      } else {
        try {
          const res = await fetch(`https://maps.google.com/maps/api/geocode/json?address=${encodeURIComponent(place.name)}&key=AIzaSyDQfr3V_JHN9CuguMFBCYV8ZLd7xEC4B-U`)
          const response = await res.json()
          if (response && response.results && response.results.length > 0) {
            location = response.results[0]
          }
          address = place.name
        } catch (e) {
          console.log(e)
        }
      }
      this.canGetAddress = false
      if (location) {
        this.currentPlace = location;
        this.deal.address = address
        await this.getLatLng()
        this.setLocation()
        this.panToMarker();
      }
    },
    async loadDeal() {
      if (!this.$route.query.id) return;
      this.isEdit = true;
      this.isView = !!this.$route.query.view
      this.loading = true;
      this.deal = await this.service.fetchOne(this.$route.query.id)
      this.deal.startDateTime = moment(this.deal.startDateTime).format('YYYY-MM-DD HH:MM')
      this.deal.endDateTime = moment(this.deal.endDateTime).format('YYYY-MM-DD HH:MM')
      this.uploadedMediaFiles = [...this.deal.images.map((item) => {
        return {
          url: axios.defaults.baseURL + '/uploads/' + item,
          fileName: item,
        };
      })];

      if (this.deal.contact) {
        const phone = parsePhoneNumber(this.deal.contact)
        if (phone && phone.isValid()) {
          this.phone = phone.nationalNumber
          this.countryCode = phone.country
        }
      }
      if (this.deal.location && this.deal.location.coordinates && this.deal.location.coordinates.length > 0) {
        this.location = this.deal.location.coordinates.join(',')
      }
      this.loading = false;
    },
    clearForm() {
      this.media = []
      this.phone = null
      this.location = null
      this.deal.images = []
      this.loadDeal()
    },
    preCheck(context) {
      this.showPhoneError = false
      let error = false
      if (!context.validate()) {
        error = true
      }
      if (!this.deal.contact || this.phoneError) {
        this.phoneError = true
        this.showPhoneError = true
        error = true
      }
      return !error;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if(this.filesToBeDeleted && this.filesToBeDeleted.length > 0) {
          this.deal.images = [...this.deal.images.filter((item) => !this.filesToBeDeleted.includes(item))]
        }

        try {
          if (this.media && this.media.length > 0) {
            if (this.deal.images && this.deal.images.length > 0) {
              this.deal.images = [
                ...this.deal.images,
                ...(await uploadWithMessage(this.$refs.form, this.media, 'Uploading Images'))
              ];
            } else {
              this.deal.images = [
                ...(await uploadWithMessage(this.$refs.form, this.media, 'Uploading Images'))
              ];
            }
            this.$refs.form.showLoading(false, '')
          }
          if (this.location) {
            this.deal.location.type = 'Point'
            this.deal.location.coordinates = this.location.split(',').map((i) => parseFloat(i))
          }

        } catch (e) {
          console.log(e)
          context.reportError({
            title: 'Error occurred',
            description: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occurred. Try again later.',
          });
          return false
        }

        if (this.isEdit) {
          try {
            this.$refs.form.showLoading(true, 'Updating deal...')
            await this.service.update(this.deal._id, this.deal)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Deal updated successfully')
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating deal',
              message: e.toString()
            });
            return false
          }
        } else {
          try {
            this.$refs.form.showLoading(true, 'Creating deal...')
            await this.service.create(this.deal)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Deal created successfully')
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating deal',
              description: e.toString(),
              okButton: 'OK'
            });
            return false
          }
        }
      }
    },
    geoLocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        };
        this.setLocation()
        this.panToMarker();
        this.getAddress()
      }, (err) => {
        console.log(`ERROR(${err.code}): ${err.message}`);
      });
    },
    handleMarkerDrag(e) {
      this.marker.position = {
        lng: e.latLng.lng(),
        lat: e.latLng.lat()
      };
      this.setLocation()
    },
    panToMarker() {
      this.center = this.marker.position
    },
    setLocation() {
      this.location = this.marker.position.lng + ',' + this.marker.position.lat
      this.getAddress()
      this.setCoordinates()
    },
    handleMapClick(e) {
      if (!this.isView) {
        this.marker.position = {
          lng: e.latLng.lng(),
          lat: e.latLng.lat(),
        };
        this.setLocation()
      }
    },
    async getAddress() {
      if (this.canGetAddress) {
        try {
          await fetch('https://maps.googleapis.com/maps/api/geocode/json?latlng=' + this.marker.position.lat + ',' + this.marker.position.lng + '&key=AIzaSyDQfr3V_JHN9CuguMFBCYV8ZLd7xEC4B-U')
              .then(response => response.json())
              .then(res => {
                this.deal.address = res.results[0].formatted_address
              })
        } catch (e) {
          console.log(e, 'Address error')
        }
      }
      this.canGetAddress = true
    },
    async getLatLng() {
      if (typeof this.currentPlace.geometry.location.lat === 'function') {
        this.marker.position = {
          lng: this.currentPlace.geometry.location.lng(),
          lat: this.currentPlace.geometry.location.lat(),
        };
      } else {
        this.marker.position = {
          lng: this.currentPlace.geometry.location.lng,
          lat: this.currentPlace.geometry.location.lat,
        };
      }
      this.setCoordinates()
    },
    setCoordinates() {
      if (!this.deal.location) {
        this.deal.location = {
          type: 'Points',
          coordinates: []
        }
      }
      this.deal.location.coordinates.push(this.marker.position.lng)
      this.deal.location.coordinates.push(this.marker.position.lat)
    },

    // media related methods
    removeMedia(media) {
      if (media.file) {
        const index = this.selectedMedia.indexOf(media.file)
        this.selectedMedia.splice(index, 1)
      }
      const indexMedia = this.media.indexOf(media)
      this.media.splice(indexMedia, 1)
      this.uploadedFiles = this.media.length
    },
    pickFile() {
      document.querySelector("#mediaPicker").click();
    },
    pickHandler() {
      this.uploadedFiles = 0
      this.handleMedia(this.selectedMedia)
      this.media = [...this.media]
    },
    async dropHandler(ev) {
      ev.preventDefault();
      this.uploadedFiles = 0
      await this.handleMedia(ev.dataTransfer.files)
      this.media = [...this.media]
    },
    async handleMedia(files) {
      // const allowedTypes = ['png', 'jpg', 'jpeg', 'gif','mp4'];
      for (let i = 0; i < files.length; i++) {
        if ((files[i].type.includes('image') && files[i].size < 5242880) || (files[i].type.includes('video') && files[i].size < 209715200)) {
          try {
            const fileData = {
              file: files[i],
              url: URL.createObjectURL(files[i]),
              uploaded: 0,
              isUploaded: false,
              name: files[i].name,
              type: files[i].type,
              size: files[i].size
            }
            fileData.uploaded = 100
            fileData.isUploaded = true
            this.media.push(fileData);
            this.uploadedFiles = this.media.length
          } catch (e) {
            this.$toast.error('Some error occurred. ' + files[i].name + ' cannot be selected.')
          }
        } else {
          this.$toast.error(files[i].type.includes('image') ? files[i].name + ' is too large. Max size allowed is 5 Mb' : files[i].name + ' is too large. Max size allowed is 200 Mb')
        }
      }
    },
    removeUploadedFiles(fileName, fileIndex) {
      this.uploadedMediaFiles.splice(fileIndex, 1)
      const index = this.deal.images.findIndex((file) => file === fileName)
      if (index !== -1) {
        this.filesToBeDeleted.push(fileName)
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.mediaPrickerWrapper {
  border: 1px dashed #2177b0;
  border-radius: 8px;
}

.file-display {
  border: 1px solid #2177b0;
}

.contact-upload-dialog p, .contact-upload-dialog a {
  font-size: 14px;
}

.preview-image {
  width: 120px !important;
  height: 120px !important;
}

.remove-icon {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  cursor: pointer !important;
}
</style>