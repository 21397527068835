<template>
  <v-card>
    <v-card-title class="d-flex flex-column flex-md-row justify-start align-start"
                  :style="$vuetify.breakpoint.mdAndUp ? '' : 'gap: 10px'">
      <span class="data-table__header">{{ title }}</span>
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"/>

      <div v-if="showSearch"
           :style="$vuetify.breakpoint.mdAndUp ? 'width: 400px; margin-right: 20px; max-width: 100%' : 'width: 100%'">
        <v-text-field
            v-model="search"
            solo
            label="Search anything..."
            dense
            hide-details
            @change="searchData"
            append-icon="mdi-magnify"
            @click:append="searchData"
        />
      </div>
      <slot name="primary-action"/>
      <v-btn
          v-if="allowAdd"
          color="primary"
          :width="$vuetify.breakpoint.mdAndUp ? 'fit-content' : '100%'"
          elevation="0"
          @click="$emit('add-new')"
      >
        <v-icon class="v-btn__pre-icon" small>mdi-plus</v-icon>&nbsp; Add New
      </v-btn>

      <v-btn id="refresh" class="refresh" :width="$vuetify.breakpoint.mdAndUp ? 'fit-content' : '100%'"
             :icon="$vuetify.breakpoint.mdAndUp"
             :style="$vuetify.breakpoint.mdAndUp ? 'margin-left: 15px' : ''" @click="loadData">
        <v-icon :small="!$vuetify.breakpoint.mdAndUp">mdi-refresh</v-icon>
        <span v-if="!$vuetify.breakpoint.mdAndUp">&nbsp; Reload</span>
      </v-btn>

      <v-btn v-if="allowFilters" icon style="margin-left: 10px">
        <v-icon @click="$emit('filter')">mdi-filter</v-icon>
      </v-btn>
      <slot name="secondary-actions"/>
    </v-card-title>

    <v-data-table
        :loading="loading"
        :items="items"
        :headers="headersValue"
        height="calc(100vh - 270px)"
        :hide-default-footer="!defaultFooter"
        :disable-pagination="pagination"
        :items-per-page="limit"
    >
      <template v-slot:item="{ item }">
        <tr v-if="$vuetify.breakpoint.smAndUp">
          <td
              v-for="(elem, key) of headers"
              :key="key"
              :class="`text-${elem.align === 'right' ? 'end' : 'start'}`"
          >
            <slot :name="elem.value" :item="item">{{ item[elem.value] }}</slot>
          </td>

          <td
              v-if="showActions"
              class="text-end"
          >
            <slot name="extra-actions" :item="item"/>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="mx-1 my-1" v-if="viewHandler" small @click="viewHandler(item)" color="white"
                        style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)" v-bind="attrs"
                        v-on="on"
                >
                  mdi-eye
                </v-icon>
              </template>
              <span>View</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-if="editHandler"
                    small
                    @click="editHandler(item)"
                    color="white"
                    class="mr-1"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-pencil
                </v-icon>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    v-if="deleteHandler"
                    small
                    @click="onDelete(item)"
                    color="white"
                    class="mx-1 my-1"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                >
                  mdi-delete
                </v-icon>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </td>
        </tr>
        <tr v-else class="v-data-table__mobile-table-row">
          <td
              v-for="(elem, key) of headers"
              :key="key"
              :class="`v-data-table__mobile-row text-${elem.align === 'right' ? 'end' : 'start'}`"
          >
            <div class="v-data-table__mobile-row__header">{{ elem.text }}</div>
            <div class="v-data-table__mobile-row__cell">
              <slot :name="elem.value" :item="item">{{ item[elem.value] }}</slot>
            </div>
          </td>

          <td
              class="v-data-table__mobile-row text-end" style="text-align: right"
          >
            <div class="v-data-table__mobile-row__header">Action</div>
            <div class="v-data-table__mobile-row__cell">
              <slot name="extra-actions" :item="item"/>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-1 my-1" v-if="viewHandler" small @click="viewHandler(item)" color="white"
                          style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)" v-bind="attrs"
                          v-on="on"
                  >
                    mdi-eye
                  </v-icon>
                </template>
                <span>View</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      v-if="editHandler"
                      small
                      @click="editHandler(item)"
                      color="white"
                      class="mr-1"
                      style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                      v-if="deleteHandler"
                      small
                      @click="onDelete(item)"
                      color="white"
                      style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                      v-bind="attrs"
                      v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>Delete</span>
              </v-tooltip>
            </div>
          </td>
        </tr>
      </template>
      <template v-slot:footer>
        <div style="border-top: thin solid rgba(0, 0, 0, 0.12)">
          <div :class="`d-flex pa-2 justify-end align-center ${$vuetify.breakpoint.smAndDown ? 'flex-column' : ''}`"
               style="gap:15px">
            <v-select v-model="limit" outlined hide-details dense :items="limits"
                      :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 100px' : ''" @change="loadData"></v-select>
            <div v-if="meta && meta.data" :class="$vuetify.breakpoint.mdAndUp ? 'text-caption' : ''">
              <p class="ma-0">{{ first }} - {{ last }} of {{
                  meta.totalData
                }}</p>
            </div>
            <div class="d-flex justify-center align-center" style="gap: 15px">
              <v-btn elevation="0"
                     color="primary" class="grow" :disabled="page <= 0" @click="updatePage(false)">Prev
              </v-btn>
              <v-btn elevation="0" color="primary" class="grow" :disabled="items.length < limit"
                     @click="updatePage(true)">Next
              </v-btn>
            </div>
          </div>
        </div>
      </template>
    </v-data-table>
    <error-dialogue ref="error"/>
  </v-card>
</template>

<script>

export default {
  name: 'DataTable',
  props: {
    allowAdd: {
      type: Boolean,
      default: true
    },
    defaultFooter: {
      type: Boolean,
      default: false
    },
    showSearch: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: false
    },

    allowFilters: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      default: null
    },

    loader: {
      type: Function,
      required: true
    },

    headers: {
      type: Array,
      required: true,
    },

    editHandler: {
      type: Function,
      default: null
    },

    viewHandler: {
      type: Function,
      default: null
    },

    deleteHandler: {
      type: Function,
      default: null
    },
    deleteMessage: {
      type: String,
      default: 'This item will be deleted. Are you sure?'
    },
  },

  emits: ['add-new'],

  mounted() {
    this.headersValue = [...this.headers];
    if (this.showActions) {
      this.headersValue.push({
        text: 'Actions',
        align: 'right',
        search: false,
        sortable: false
      });
    }

    this.loadData();
  },

  data: () => ({
    search: '',
    items: [],
    meta: {},
    loading: false,
    headersValue: [],
    limits: [
      15, 20, 25, 30
    ],
    limit: 20,
    page: 0,
  }),

  methods: {
    reportError(error) {
      this.$refs.error.show(error)
    },
    showLoading(val) {
      this.loading = val
    },
    async loadData() {
      this.loading = true;
      try {
        this.meta = await this.loader(this.page, this.limit, this.search)
        if (Array.isArray(this.meta)) {
          this.items = this.meta;
        } else {
          this.items = this.meta.data;
        }

        this.loading = false;
      } catch (e) {
        this.loading = false;
        let message
        if (e && e.response && e.response.data && e.response.data.error)
          message = e.response.data.error
        else
          message = e.message || 'Some Error occurred'
        this.$refs.error.show({
          title: 'Error while loading data',
          message,
          okButton: 'OK'
        })
      }
    },
    updatePage(flag) {
      if (flag)
        this.page++
      else
        this.page--
      this.loadData()
    },
    searchData() {
      this.page = 0
      this.loadData()
    },
    async onDelete(item) {
      if (confirm(this.deleteMessage)) {
        try {
          this.loading = true;
          await this.deleteHandler(item);
          this.loading = false;
          this.items.splice(this.items.indexOf(item), 1);
        } catch (e) {
          window.console.log(e);
        }
      }
    },
  },

  computed: {
    first() {
      const first = (this.limit * this.meta.page) + 1
      return first > this.meta.totalData ? 0 : first
    },
    last() {
      const last = (this.limit * this.meta.page) + this.limit
      if (last > this.meta.totalData)
        return this.meta.totalData
      else
        return last
    }
  }
};
</script>

<style lang="sass" scoped>
.data-table

  &__header
    font-size: 25px
    font-family: google-sans, sans-serif
</style>