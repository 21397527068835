import axios from 'axios';

export class EventDealService {
    async fetchAll(page = 0, limit = 20, search = '', status = '', type, city = '', sortBy = '', sortByDir = '') {
        return (await axios.get(
                '/event-deal?page=' + page +
                '&limit=' + limit +
                '&search=' + search +
                '&type=' + type +
                (status ? '&status=' + status : '') +
                (city ? '&city=' + city   : '') +
                (sortBy ? '&sort=' + sortBy : '') +
                (sortByDir ? '&sortType=' + sortByDir : '')
            )
        ).data;
    }

    async fetchOne(id) {
        return (await axios.get('/event-deal/' + id)).data;
    }

    async update(id, item) {
        return (await axios.patch('/event-deal/' + id, item)).data;
    }

    async create(item) {
        return (await axios.post('/event-deal', item)).data;
    }
    async delete(item) {
        return (await axios.delete('/event-deal/' + item._id)).data;
    }
}