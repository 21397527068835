<template>
  <v-container class="text-center my-5">
    <v-card class="pa-4">
      <v-icon large color="primary" class="mb-3">mdi-archive-remove-outline</v-icon>
      <h3>You don't have permission to access this page.</h3>
      <h4 class="text-subtitle-1">Contact your manager for permission to access this page.</h4>
      <h4 class="text-subtitle-1">If you already have acquired permission. Kindly <span class="primary--text pointer"
                                                                                        @click="reload">Reload</span>
      </h4>
      <div>
        <router-link to="/" class="mr-3">Dashboard</router-link>
      </div>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "permission-required",
  methods: {
    reload() {
      console.log('reload')
      location.reload()
    }
  }
}
</script>

<style scoped>

</style>