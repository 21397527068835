var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v("All")]),_c('v-tab',[_vm._v("Pending")]),_c('v-tab',[_vm._v("Approved")]),_c('v-tab',[_vm._v("Rejected")])],1),_c('v-tabs-items',{staticClass:"elevation-1",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.tabs),function(item,i){return _c('v-tab-item',{key:i},[_c('DataTable',{ref:"datatable",refInFor:true,attrs:{"title":item.title,"headers":_vm.headers,"loader":_vm.load,"allow-add":_vm.checkScope(['events:new']),"show-actions":_vm.checkScope(['events:new','events:detail','events:edit','events:delete']),"edit-handler":_vm.checkScope(['events:edit']) ? _vm.edit : null,"view-handler":_vm.checkScope(['events:detail']) ? _vm.view : null,"delete-handler":_vm.checkScope(['events:delete']) ? _vm.service.delete : null},on:{"add-new":_vm.add},scopedSlots:_vm._u([{key:"cover",fn:function(ref){
var item = ref.item;
return [(item && item.images.length > 0)?_c('v-avatar',{staticStyle:{"margin":"5px","padding":"0"}},[_c('img',{staticStyle:{"object-fit":"cover"},attrs:{"alt":"multiImage","src":_vm._f("image")(item.images[0])}})]):_c('p',{staticStyle:{"margin":"0"}},[_vm._v(" No Image ")])]}},{key:"startDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUtcDateTimeIntoLocal(item.startDateTime))+" ")]}},{key:"endDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUtcDateTimeIntoLocal(item.endDateTime))+" ")]}},{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUtcDateTimeIntoLocal(item.createdAt))+" ")]}},{key:"extra-actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((item.status === 'Pending' || item.status === 'Rejected') && _vm.tab !== 0 && _vm.checkScope(['events:status']))?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1 my-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.updateStatus(item, 'Approved')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Approve")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [((item.status === 'Pending' || item.status === 'Approved') && _vm.tab !== 0 && _vm.checkScope(['events:status']))?_c('v-icon',_vm._g(_vm._b({staticClass:"mx-1 my-1",staticStyle:{"padding":"5px","border-radius":"6px","background":"rgba(0,0,0,0.4)"},attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.updateStatus(item, 'Rejected')}}},'v-icon',attrs,false),on),[_vm._v(" mdi-close ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Reject")])])]}},{key:"secondary-actions",fn:function(){return [_c('v-menu',{attrs:{"close-on-click":false,"close-on-content-click":false,"min-width":"200","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-left":"10px"},attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}],null,true),model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}},[_c('v-card',{staticClass:"pa-4"},[_c('p',[_vm._v("Filter")]),_c('v-text-field',{attrs:{"dense":"","label":"City","outlined":"","type":"text"},model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}}),_c('v-select',{attrs:{"items":_vm.sortByList,"dense":"","item-text":"title","item-value":"value","label":"Sort By","outlined":""},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-select',{attrs:{"items":_vm.sortByDirList,"dense":"","item-text":"title","item-value":"value","label":"Sort By","outlined":""},model:{value:(_vm.sortByDir),callback:function ($$v) {_vm.sortByDir=$$v},expression:"sortByDir"}}),_c('div',{staticClass:"d-flex",staticStyle:{"gap":"10px"}},[_c('v-btn',{staticClass:"flex-grow-1",attrs:{"elevation":"0"},on:{"click":_vm.clearFilter}},[_vm._v(" Clear ")]),_c('v-btn',{staticClass:"flex-grow-1",attrs:{"color":"primary","elevation":"0"},on:{"click":_vm.applyFilter}},[_vm._v(" Filter ")])],1)],1)],1)]},proxy:true}],null,true)})],1)}),1),_c('confirm-dialogue',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }