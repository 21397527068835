export function required(message = "You can't leave this field empty") {
    return (v) => !!v || message
}

export function dateValidation(value) {
    const currentDate = new Date()
    const givenDate = new Date(value)
    return givenDate > currentDate || 'Incorrect Date'
}

export function filterDateValidation(from,to) {
    const currentDate = new Date(from)
    const givenDate = new Date(to)
    return givenDate >= currentDate || 'Incorrect Date'
}


export const requiredWithZero = (value) =>
    (+value >= 0 && value !== '') || "You can't leave this field empty"


export function requiredPercentage(message = "Percentage is not accurate!") {
    return (v) => !!v && v > 0 && v <= 100 || message
}

export function requiredArray(message = "You can't leave this field empty") {
    return (v) => !!v && v.length > 0 || message
}

export function email(message = "This email is not valid") {
    return (v) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v)) || message
}

export function url(message = "This url is not valid") {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return (v) => (pattern.test(v)) || message
}

export function minChar(message = "You are very close to 8 characters.") {
    return (v) => (/.{8,}$/.test(v)) || message
}

export function oneAlphabet(message = "Try including a letter") {
    return (v) => (/[a-zA-Z]/.test(v)) || message
}

export function oneNumber(message = "Try including a number") {
    return (v) => (/\d/.test(v)) || message
}

export function name(message = "Name is not valid") {
    return (v) => (v && /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-])+$/u.test(v) && v.length < 50) || message
}


export function charLimit(message = "Name is not valid", char) {
    return (v) => (v && v.length < char) || message
}

export const maxChar = (value) =>
    (value && value.length < 20) || "Try using less than 20 characters."

