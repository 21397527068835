import Businesses from "../../views/businesses/index";
import Business from "../../views/businesses/form"
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const businessesRoutes = [
    {
        name: 'Businesses',
        path: '/businesses',
        component: checkScope(['businesses:view']) ? Businesses : PermissionRequired
    },
    {
        name: 'Business',
        path: '/business',
        component: checkScope(['businesses:detail','businesses:new','businesses:edit']) ? Business : PermissionRequired
    },
];

export const businessesRouter = businessesRoutes.filter(function (x) {
    return x !== null;
})