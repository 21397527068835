import Reviews from "../../views/reviews/index";
import Review from "../../views/reviews/form";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const reviewsRoutes = [
    {
        name: 'Reviews',
        path: '/reviews',
        component: checkScope(['reviews:view']) ? Reviews : PermissionRequired
    },
    {
        name: 'Review',
        path: '/review',
        component: checkScope(['reviews:detail','reviews:edit']) ? Review : PermissionRequired
    },
];

export const reviewsRouter = reviewsRoutes.filter(function (x) {
    return x !== null;
})