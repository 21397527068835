<template>
  <v-card class="form">
    <v-form ref="form" class="form__body">
      <slot/>
    </v-form>

    <div class="form__actions">
      <slot name="actions" :context="submitContext"/>

      <v-btn :disabled="disabled || loading" elevation="0" color="primary" @click="submit">
        <span style="padding: 0 10px">{{ loading ? 'Submitting' : 'Submit' }}</span>
        <v-progress-circular v-if="loading" size="18" width="2" class="ml-2" indeterminate/>
      </v-btn>
    </div>

    <error-dialogue ref="error"/>
    <loading-dialogue ref="loading"/>
  </v-card>
</template>

<script>

import LoadingDialogue from "@/components/dialogues/LoadingDialogue";

export default {
  name: 'SimpleForm',
  components: {LoadingDialogue},
  props: {
    onSubmit: {
      type: Function,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    resetAllowed: {
      type: Boolean,
      default: true,
      required: false
    }
  },

  data: () => ({
    loading: false,
    submitContext: {}
  }),

  mounted() {
    this.submitContext.validate = this.$refs.form.validate;
    this.submitContext.reportError = this.reportError;
    this.submitContext.changeLoadingStatus = this.changeLoadingStatus;
  },

  methods: {
    changeLoadingStatus(status = true) {
      this.loading = status;
    },
    reportError(error) {
      this.$refs.error.show(error)
    },
    showLoading(val, message) {
      if (val)
        this.$refs.loading.show({message})
      else
        this.$refs.loading.hide()
    },
    async submit() {
      this.changeLoadingStatus(true);
      if (await this.onSubmit(this.submitContext)) {
        this.changeLoadingStatus(false);
        if (this.resetAllowed)
          this.$refs.form.reset();
        this.$emit('done');
      } else {
        this.changeLoadingStatus(false);
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.form
  width: 650px
  padding: 40px
  margin: 50px auto
  border-radius: 8px

  &__body
    display: grid
    max-width: 100%
    grid-column-gap: 20px
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)

  &__actions
    display: flex
    margin-top: 30px
    justify-content: flex-end
</style>

<style lang="sass">
.span-2
  grid-column: 1 / 3

.form
  &__title
    font-weight: normal !important
    text-align: center !important
    font-size: 27px
    padding-bottom: 20px
    font-family: google-sans, sans-serif
</style>
