<template>
  <v-dialog v-model="isVisible" :fullscreen="fullscreen" :width="$vuetify.breakpoint.mdAndUp ? widthLG : widthSM" :persistent="isPersistent">
    <v-card class="pa-4">
      <slot></slot>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'PopupModal',
  props: {
    isPersistent: {
      type: Boolean,
      default: false
    },
    widthLG: {
      type: String,
      default: '40%'
    },
    widthSM: {
      type: String,
      default: '90%'
    },
    fullscreen: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    isVisible: false,
  }),

  methods: {
    open() {
      this.isVisible = true
    },

    close() {
      this.isVisible = false
    },
  },
}
</script>

<style scoped></style>