import axios from 'axios';

export class CategoryService {
    async fetchAll(page = 0, limit = 20, search = '') {
        return (await axios.get('/category?page=' + page + '&limit=' + limit + '&search=' + search)).data;
    }

    async fetchOne(id) {
        return (await axios.get('/category/' + id)).data;
    }

    async update(id, item) {
        return (await axios.patch('/category/' + id, item)).data;
    }
}