import SubAdmin from "../../views/subAdmins/form";
import SubAdmins from "../../views/subAdmins/index";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const subAdminsRoutes = [
    {
        name: 'SubAdmins',
        path: '/sub-admins',
        component: checkScope(['sub-admins:view']) ? SubAdmins : PermissionRequired
    },
    {
        name: 'SubAdmin',
        path: '/sub-admin',
        component: checkScope(['sub-admins:new','sub-admins:detail','sub-admins:edit']) ? SubAdmin : PermissionRequired
    },
];

export const subAdminsRouter = subAdminsRoutes.filter(function (x) {
    return x !== null;
})