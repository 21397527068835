<template>
  <div>
    <DataTable
        title="Users"
        :headers="headers"
        :loader="load"
        :allow-add="false"
        :view-handler="checkScope(['users:detail']) ? view : null"
        :default-footer="false"
        pagination
        :show-actions="checkScope(['users:detail','users:status'])"
        ref="datatable"
    >
      <template #contact="{item}">
        <span v-if="item.contact">
          {{ item.contact | phone }}
        </span>
      </template>
      <template #gender="{item}">
        {{ item.gender | uppercase }}
      </template>
      <template #dob="{item}">
        {{ item.dob | date('MMMM DD, YYYY') }}
      </template>
      <template #createdAt="{item}">
        {{ item.createdAt | date('MM/DD/YYYY') }}
      </template>
      <template #updatedAt="{item}">
        {{ item.updatedAt | date('MM/DD/YYYY') }}
      </template>
      <template v-slot:extra-actions="{item}">
        <v-tooltip top v-if="checkScope(['users:status'])">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-1 my-1" small @click="statusHandler(item)" color="white"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)" v-bind="attrs"
                    v-on="on"
            >
              {{ item.status === 'Active' ? 'mdi-account-cancel' : 'mdi-account' }}
            </v-icon>
          </template>
          <span>{{ item.status === 'Active' ? 'Block' : 'Unblock' }}</span>
        </v-tooltip>
      </template>
      <template v-slot:secondary-actions>
        <v-menu v-model="filter" offset-y min-width="200" :close-on-content-click="false" :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on"
                   icon
                   style="margin-left: 10px">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-4">
            <div class="d-flex justify-space-between mb-2">
              <p class="ma-0">Filter</p>
              <v-icon @click="filter = false">mdi-close</v-icon>
            </div>
            <v-form ref="form">
              <v-select v-model="loginVia" :items="loginMethods" outlined label="Login Method" dense></v-select>
              <v-select v-model="businessUsers" :items="businessUsersList" item-text="title" item-value="value" outlined
                        label="User Type" dense></v-select>
              <v-select v-model="sortBy" :items="sortList" item-text="title" item-value="value" outlined
                        label="Sort By" dense></v-select>
              <v-text-field v-model="fromDate" :rules="[ toDate ? required() : true ]" type="date" outlined label="From"
                            dense/>
              <v-text-field v-model="toDate"
                            :rules="[ fromDate ? required() : true,fromDate ? filterDateValidation(fromDate,toDate)  : true ]"
                            type="date" outlined label="To" dense/>
              <div class="d-flex" style="gap:10px">
                <v-btn @click="clearFilter" elevation="0" class="flex-grow-1">
                  Clear
                </v-btn>
                <v-btn @click="applyFilter" elevation="0" color="primary" class="flex-grow-1">
                  Filter
                </v-btn>
              </div>
            </v-form>
          </v-card>
        </v-menu>
      </template>
    </DataTable>
    <confirm-dialogue ref="confirm"/>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {PersonService} from "../../services/person";
import {checkScope} from "../../utils/local";
import {required, filterDateValidation} from "../../utils/validators";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      service: new PersonService(),
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Email',
          value: 'email'
        },
        {
          text: 'Date of birth',
          value: 'dob'
        },
        {
          text: 'Contact #',
          value: 'contact'
        },
        {
          text: 'Gender',
          value: 'gender'
        },
        {
          text: 'Login Method',
          value: 'loginVia'
        },
        {
          text: 'Created',
          value: 'createdAt'
        },
        {
          text: 'Updated',
          value: 'updatedAt'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      loginMethods: [
        'Email',
        'Phone',
        'Google',
        'Facebook',
        'Apple'
      ],
      loginVia: null,
      businessUsersList: [
        {title: 'Business User', value: true},
        {title: 'Non-Business User', value: false}
      ],
      businessUsers: null,
      fromDate: null,
      toDate: null,
      filter: false,
      sortBy: 'none',
      sortList: [
        {title: 'None', value: 'none'},
        {title: 'Name', value: 'name'},
        {title: 'Joining Date', value: 'createdAt'},
      ],
    }
  },
  methods: {
    checkScope,
    required,
    filterDateValidation,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search, this.loginVia, this.businessUsers, this.fromDate, this.toDate, this.sortBy)
    },
    reloadData() {
      document.getElementById('refresh').click()
    },
    view(item) {
      let routeData = this.$router.resolve('/user?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/user?id=' + item._id + '&view=true')
    },
    async statusHandler(item) {
      const status = item.status === 'Active' ? 'Blocked' : 'Active'
      const confirm = await this.$refs.confirm.show({message: item.status === 'Active' ? 'User will be blocked.' : 'User will be unblocked.'})
      if (confirm) {
        try {
          this.$refs.datatable.showLoading(true)
          await this.service.update(item._id, {status})
          await this.$refs.datatable.loadData()
          this.$refs.datatable.showLoading(false)
          this.$toast.success('User status updated successfully')
        } catch (e) {
          this.$refs.datatable.showLoading(false)
          this.$refs.datatable.reportError({
            title: 'Error Occurred',
            message: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occurred. Try again later.',
          })
        }
      }
    },
    applyFilter() {
      if (this.sortBy !== null || this.loginVia !== null || this.businessUsers !== null || (this.$refs.form.validate() && this.fromDate && this.toDate)) {
        this.filter = false
        this.$refs.datatable.loadData()
      }
    },
    clearFilter() {
      this.filter = false
      this.loginVia = null
      this.businessUsers = null
      this.fromDate = null
      this.toDate = null
      this.$refs.form.reset()
      this.$refs.datatable.loadData()
    }
  }
}
</script>

<style scoped>

</style>