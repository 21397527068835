import Events from "../../views/events/index";
import Event from "../../views/events/form";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const eventsRoutes = [
    {
        name: 'Events',
        path: '/events',
        component: checkScope(['events:view']) ? Events : PermissionRequired
    },
    {
        name: 'Event',
        path: '/event',
        component: checkScope(['events:detail','events:new','events:edit']) ? Event : PermissionRequired
    },
];

export const eventsRouter = eventsRoutes.filter(function (x) {
    return x !== null;
})