import Vue from "vue"
import dayjs from "dayjs";
import axios from "axios";
import parsePhoneNumber from 'libphonenumber-js'

Vue.filter('phone', function (phone, country = 'PK') {
    const phoneNumber = parsePhoneNumber(phone, country)
    if (phoneNumber && phoneNumber.isValid()) {
        return phoneNumber.formatInternational()
    } else {
        return 'Invalid Contact'
    }
});
Vue.filter('capitalize', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('uppercase', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.toUpperCase()
})
Vue.filter('lowercase', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.toLowerCase()
})
Vue.filter('join', function (value) {
    if (!value || value.length <= 0) return ''
    return value.join(', ')
})

Vue.filter('date', function (value, format) {
    if (dayjs(value).format(format) === 'Invalid Date')
        return ''
    else
        return dayjs(value).format(format)
})

Vue.filter('image', function (value) {
    return axios.defaults.baseURL + '/uploads/' + value
})

Vue.filter('shortInt', function (value) {
    value = value.toString().replace(/[^0-9.]/g, '');
    if (value < 1000) {
        return value;
    }
    let si = [
        {v: 1E3, s: "K"},
        {v: 1E6, s: "M"},
        {v: 1E9, s: "B"},
        {v: 1E12, s: "T"},
        {v: 1E15, s: "P"},
        {v: 1E18, s: "E"}
    ];
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (value >= si[index].v) {
            break;
        }
    }
    return (value / si[index].v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + si[index].s;
})