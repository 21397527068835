<template>
  <div id="background" class="d-flex justify-center align-center" style="height: 100vh">
    <div class="sign-in rounded-lg elevation-10" style="border: 3px solid #ee6725; background-color: white">
      <img src="../../assets/logo.png" alt="4Dost" class="logo sign-in__logo"
           style="max-width: 100%; width: 100px;height: 100px"/>
      <div v-if="error">
        <v-icon class="mb-4" x-large :color="(errorVal.status === 500 || errorVal.status === 406) ? 'red' : 'green'">
          {{ (errorVal.status === 500 || errorVal.status === 406) ? "mdi-close" : "mdi-check" }}
        </v-icon>
        <p>{{ errorVal.title }}</p>
        <p>{{ errorVal.description }}</p>
      </div>
      <div v-else-if="success">
        <v-icon class="mb-4" x-large color="green">
          mdi-check
        </v-icon>
        <p>{{ successVal.title }}</p>
        <p>{{ successVal.description }}</p>
      </div>
    </div>
    <loading-dialog v-model="loading" message="You are being authenticated, Please wait..."/>
  </div>
</template>

<script>
import LoadingDialog from "../../components/dialogues/LoadingDialogue.vue";

export default  {
  name: "VerifyAccount",
  components: {LoadingDialog},
  mounted() {
    this.verifyAccount()
  },
  data() {
    return {
      loading: false,
      error: false,
      errorVal: {},
      success: false,
      successVal: {}
    };
  },
  methods: {
    async verifyAccount() {
      const data = {
        id: this.$route.params.id,
        hash: this.$route.params.hash
      };
      try {
        console.log(data)
        await this.$axios.patch("persons/verify-account", data);
        const success = true;
        const successVal = {
          title: "Account Verified",
          description: "Your account is now verified. Please reload your app."
        };
        this.success = success
        this.successVal = successVal
      } catch (e) {
        console.log(e, "error");
        this.error = true;
        switch (e.status) {
          case 500:
            this.errorVal = {
              title: "Unexpected Error",
              description: e.response.data.message || e.data.message ? e.response.data.message || e.data.message : 'Some error occurred',
              status: 500
            };
            break;
          case 406:
            this.errorVal = {
              title: "Invalid Verification",
              description: e.response.data.message || e.data.message ? e.response.data.message || e.data.message : 'Some error occurred',
              status: 406
            };
            break;
          case 409:
            this.errorVal = {
              title: "Already Verified",
              description: e.response.data.message || e.data.message ? e.response.data.message || e.data.message : 'Some error occurred',
              status: 409
            };
            break;
        }
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  text-align: center
  background: white
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 80%
    margin-bottom: 0px

  &__message
    text-align: left
    font-size: 13px
    margin-bottom: 40px

  &__message-below
    font-size: 13px
    margin-top: 20px


#background
  display: flex
  align-items: center
  justify-content: center
  //background: #7c1e1e
  background: #F4F5FA

.text-divider
  margin: 2em 0
  line-height: 0
  text-align: center

.text-divider span
  background-color: #ffffff
  padding: 1em

.text-divider:before
  content: " "
  display: block
  border-top: 1px solid #e3e3e3
  border-bottom: 1px solid #f7f7f7

//.logo-box {
//  padding: 10px;
//  margin: -120px auto 30px auto;
//}
//
//.heading {
//  font-size: 24px;
//  font-weight: normal;
//  margin-bottom: 20px;
//  font-family: google-sans, sans-serif;
//}
</style>

<style>
html {
  overflow-y: auto;
}
</style>