import Users from "../../views/users/index";
import User from "../../views/users/form";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const usersRoutes = [
    {
        name: 'Users',
        path: '/users',
        component: checkScope(['users:view']) ? Users : PermissionRequired
    },
    {
        name: 'User',
        path: '/user',
        component: checkScope(['users:detail']) ? User : PermissionRequired
    },
];

export const usersRouter = usersRoutes.filter(function (x) {
    return x !== null;
})