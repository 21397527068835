import Categories from "../../views/categories/index";
import Category from '../../views/categories/form'
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const categoriesRoutes = [
    {
        name: 'Categories',
        path: '/categories',
        component: checkScope(['categories:view']) ? Categories : PermissionRequired
    },
    {
        name: 'Category',
        path: '/category',
        component: checkScope(['categories:detail','categories:edit']) ? Category : PermissionRequired
    },
];

export const categoriesRouter = categoriesRoutes.filter(function (x) {
    return x !== null;
})