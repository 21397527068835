<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update Sub Admin' : 'Add New Sub Admin' }}</p>
    <p v-else class="span-2 form__title">Sub Admin Detail</p>

    <v-text-field
        v-model="subAdmin.name"
        dense
        :readonly="isView"
        label="Name"
        :rules="[required()]"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="subAdmin.email"
        dense
        :readonly="isView || isEdit"
        :rules="[required(), email()]"
        label="Email"
        outlined
        class="span-2"
    />
    <v-text-field
        v-model="subAdmin.password"
        label="Password"
        :type="showPassword ? 'text' : 'password'"
        dense
        :rules="[required()]"
        outlined
        v-if="!isEdit"
    />
    <v-text-field
        v-model="confirmPassword"
        label="Confirm Password"
        :rules="[required(), (v) => (v && v === subAdmin.password) || 'Passwords does not match']"
        :type="showPassword ? 'text' : 'password'"
        dense
        outlined
        v-if="!isEdit"
    />
    <v-checkbox
        v-model="showPassword"
        label="Show Password"
        style="margin-top: -15px"
        v-if="!isEdit"
        class="span-2"
    />

    <p class="span-2 mb-6">Select Sub Admin Scopes</p>
    <v-checkbox
        v-model="isAllScopesSelected"
        :readonly="isView"
        :label="isAllScopesSelected ? 'Deselect All' : 'Select All'"
        style="margin-top: -15px"
        class="span-2"
        @change="selectAllScopes"
    />

    <v-treeview
        v-model="subAdmin.scopes"
        :items="items"
        :disabled="isView"
        selectable
        selected-color="primary"
        style="text-align: left"
        class="span-2"
    />

  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {PersonService} from "@/services/person";
import {AuthService} from "@/services/auth";
import {required, email} from "@/utils/validators";

export default {
  name: 'Form',
  components: {SimpleForm},

  data: () => ({
    isEdit: false,
    isView: false,
    loading: false,
    showPassword: false,
    subAdmin: {
      name: undefined,
      email: undefined,
      username: undefined,
      status: 1,
      scopes: [],
      role: 'Admin',
      loginVia: 'Email'
    },
    allScopes: [
      "users:view",
      "users:status",
      "businesses:view",
      "businesses:new",
      "businesses:edit",
      "claimed-businesses:view",
      "claimed-businesses:edit",
      "claimed-businesses:delete",
      "categories:view",
      "categories:edit",
      "deals:view",
      "deals:new",
      "deals:edit",
      "deals:delete",
      "events:view",
      "events:new",
      "events:edit",
      "events:delete",
      "reviews:view",
      "reviews:edit",
      "notifications:view",
      "notifications:new",
      "notifications:delete",
      "sub-admins:view",
      "sub-admins:new",
      "sub-admins:edit",
      "users:detail",
      "businesses:detail",
      "claimed-businesses:detail",
      "claimed-businesses:status",
      "categories:detail",
      "deals:detail",
      "deals:status",
      "events:detail",
      "events:status",
      "reviews:detail",
      "reviews:delete",
      "sub-admins:detail",
      "sub-admins:delete"
    ],
    confirmPassword: '',
    errors: [],
    service: new PersonService(),
    authService: new AuthService(),
    isAllScopesSelected: false,
    items: [
      {
        id: 'users',
        name: 'Users',
        children: [
          {id: 'users:view', name: 'View Users'},
          {id: 'users:detail', name: 'View User Detail'},
          {id: 'users:status', name: 'Update User Status'},
        ]
      },
      {
        id: 'businesses',
        name: 'Businesses',
        children: [
          {id: 'businesses:view', name: 'View Businesses'},
          {id: 'businesses:detail', name: 'View Business Detail'},
          {id: 'businesses:new', name: 'Create Businesses'},
          {id: 'businesses:edit', name: 'Edit Business'},
        ]
      },
      {
        id: 'banners',
        name: 'Banners',
        children: [
          {id: 'banners:view', name: 'View Banners'},
          {id: 'banners:detail', name: 'View Banner Detail'},
          {id: 'banners:new', name: 'Create Banners'},
          {id: 'banners:edit', name: 'Edit Banners'},
        ]
      },
      {
        id: 'claimed-businesses',
        name: 'Claimed Businesses',
        children: [
          {id: 'claimed-businesses:view', name: 'View Claimed Businesses'},
          {id: 'claimed-businesses:detail', name: 'View Claimed Business Detail'},
          {id: 'claimed-businesses:edit', name: 'Edit Claimed Businesses'},
          {id: 'claimed-businesses:status', name: 'Update Claimed Business Status'},
          {id: 'claimed-businesses:delete', name: 'Delete Claimed Business'},
        ]
      },
      {
        id: 'categories',
        name: 'Categories',
        children: [
          {id: 'categories:view', name: 'View Categories'},
          {id: 'categories:detail', name: 'View Category Detail'},
          {id: 'categories:edit', name: 'Edit Categories'}
        ]
      },
      {
        id: 'deals',
        name: 'Deals',
        children: [
          {id: 'deals:view', name: 'View Deals'},
          {id: 'deals:detail', name: 'View Deal Detail'},
          {id: 'deals:new', name: 'Create Deals'},
          {id: 'deals:edit', name: 'Edit Deals'},
          {id: 'deals:status', name: 'Update Deal Status'},
          {id: 'deals:delete', name: 'Delete Deals'},
        ]
      },
      {
        id: 'events',
        name: 'Events',
        children: [
          {id: 'events:view', name: 'View Events'},
          {id: 'events:detail', name: 'View Event Detail'},
          {id: 'events:new', name: 'Create Events'},
          {id: 'events:edit', name: 'Edit Events'},
          {id: 'events:status', name: 'Update Event Status'},
          {id: 'events:delete', name: 'Delete Events'},
        ]
      },
      {
        id: 'reviews',
        name: 'Reviews',
        children: [
          {id: 'reviews:view', name: 'View Reviews'},
          {id: 'reviews:detail', name: 'View Review Detail'},
          {id: 'reviews:edit', name: 'Edit Reviews'},
          {id: 'reviews:delete', name: 'Delete Reviews'},
        ]
      },
      {
        id: 'notifications',
        name: 'Notifications',
        children: [
          {id: 'notifications:view', name: 'View Notifications'},
          {id: 'notifications:new', name: 'Create Notifications'},
          {id: 'notifications:delete', name: 'Delete Notifications'},
        ]
      },
      {
        id: 'sub-admins',
        name: 'Sub Admins',
        children: [
          {id: 'sub-admins:view', name: 'View Sub Admins'},
          {id: 'sub-admins:detail', name: 'View Sub Admin Detail'},
          {id: 'sub-admins:new', name: 'Create Sub Admins'},
          {id: 'sub-admins:edit', name: 'Edit Sub Admins'},
          {id: 'sub-admins:delete', name: 'Delete Sub Admin'},
        ]
      },
    ]
  }),

  mounted() {
    this.loadSubAdmin();
  },

  methods: {
    required,
    email,
    async loadSubAdmin() {
      try {
        if (!this.$route.query.id) return;
        this.isEdit = true;
        this.isView = !!this.$route.query.view
        this.$refs.form.showLoading(true, 'Loading Sub Admin Data...')
        this.subAdmin = await this.service.fetchOne(this.$route.query.id)
        this.$refs.form.showLoading(false, '')
      } catch (e) {
        this.$refs.form.showLoading(false, '')
        this.$refs.form.reportError({
          title: 'Error getting sub Admin info',
          message: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occured. Try again later.',
        })
      }
    },
    selectAllScopes(e) {
      if (e)
        this.subAdmin.scopes = this.allScopes
      else
        this.subAdmin.scopes = []
    },
    preCheck(context) {
      this.showPhoneError = false
      let error = false
      if (!context.validate()) {
        error = true
      }
      return !error;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        this.subAdmin.username = this.subAdmin.email
        if (this.isEdit) {
          try {
            console.log(this.subAdmin)
            this.$refs.form.showLoading(true, 'Updating sub admin...')
            await this.service.update(this.subAdmin._id, this.subAdmin)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Sub Admin updated successfully')
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating sub Admin',
              message: e.toString()
            });
            this.$refs.form.showLoading(false, '')
            return false
          }
        } else {
          try {
            console.log(this.subAdmin)
            this.$refs.form.showLoading(true, 'Creating sub admin...')
            await this.authService.signup(this.subAdmin)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Sub Admin created successfully')
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while creating sub Admin',
              description: e.toString(),
              okButton: 'OK'
            });
            this.$refs.form.showLoading(false, '')
            return false
          }
        }
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>