<template>
  <div>
    <DataTable
        title="Notifications"
        :headers="headers"
        :loader="load"
        :allow-add="checkScope(['notifications:new'])"
        @add-new="add"
        :show-actions="checkScope(['notifications:delete'])"
        :delete-handler="checkScope(['notifications:delete']) ? service.delete : null"
    >
      <template #image="{item}">
        <v-avatar v-if="item.image" class="my-1 pointer" @click="preview(item)">
          <img :src="item.image | image"/>
        </v-avatar>
      </template>
    </DataTable>
    <ImageViewer ref="imageViewer" />
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {checkScope} from "../../utils/local";
import {NotificationsService} from "../../services/notifications";
import ImageViewer from "../../components/dialogues/ImageViewer";

export default {
  name: "index",
  components: {DataTable, ImageViewer},
  data() {
    return {
      headers: [
        {
          text: 'Image',
          value: 'image',
          sortable: false
        },
        {
          text: 'Notification Title',
          value: 'title',
          sortable: false
        },
        {
          text: 'Description',
          value: 'description',
          sortable: false
        },
      ],
      service: new NotificationsService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search)
    },
    add() {
      let routeData = this.$router.resolve({name: 'Notification'});
      window.open(routeData.href, '_blank');

      // this.$router.push('/notification')
    },
    preview(item) {
      this.$refs.imageViewer.show({ images: [item.image] })
    }
  }
}
</script>

<style scoped>

</style>