import axios from 'axios';

export class BusinessService {
    async fetchAll(page = 0, limit = 20, search = '', status = null, sortBy = null, sortByDir = null, categoryId = null, city = null) {
        return (await axios.get('/business?page=' + page + '&limit=' + limit + '&search=' + search + (status ? '&status=' + status : '') + (sortBy ? '&sortBy=' + sortBy : '') + (sortByDir ? '&sortByDir=' + sortByDir : '') + (categoryId ? '&categoryId=' + categoryId : '') + (city ? '&city=' + city   : ''))).data;
    }

    async fetchOne(id) {
        return (await axios.get('/business/' + id)).data;
    }

    async update(id, item) {
        return (await axios.patch('/business/' + id, item)).data;
    }

    async create(item) {
        return (await axios.post('/business', item)).data;
    }

    async delete(item) {
        return (await axios.delete('/business/' + item._id)).data;
    }
}