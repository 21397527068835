import axios from 'axios';

export class AuthService {
    async getPerson() {
        return (await axios.get('/auth/profile')).data;
    }

    async signup(user) {
        return (await axios.post('/auth/sign-up', user)).data;
    }

    async signIn(user) {
        return (await axios.post('/auth/sign-in', user)).data;
    }

    async signOut() {
        return (await axios.post('/auth/sign-out')).data;
    }
}