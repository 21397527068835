import ClaimedBusinesses from "../../views/claimed-businesses/index";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const claimedBusinessesRoutes = [
    {
        name: 'claimedBusinesses',
        path: '/claimed-businesses',
        component: checkScope(['claimed-businesses:view']) ? ClaimedBusinesses : PermissionRequired
    },
];

export const claimedBusinessesRouter = claimedBusinessesRoutes.filter(function (x) {
    return x !== null;
})