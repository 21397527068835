<template>
  <div>
    <DataTable
        title="Businesses"
        ref="datatable"
        @add-new="add"
        :headers="headers"
        :loader="load"
        :allow-add="checkScope(['businesses:new'])"
        :show-actions="checkScope(['businesses:detail','businesses:edit'])"
        :edit-handler="checkScope(['businesses:edit']) ? edit : null"
        :view-handler="checkScope(['businesses:detail']) ? view : null"
    >
      <template #extra-actions="{item}">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-1 my-1" small color="white" @click="openForm(item)"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)" v-bind="attrs"
                    v-on="on"
            >
              mdi-check-circle
            </v-icon>
          </template>
          <span>Status</span>
        </v-tooltip>
        <v-tooltip v-if="item && item.status === 'Pending'" top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="mx-1 my-1" small color="white" @click="deleteItem(item)"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)" v-bind="attrs"
                    v-on="on"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Delete</span>
        </v-tooltip>
      </template>
      <template #images="{item}">
        <v-avatar
            v-if="item.images != null && item.images.length !== 0"
            style="margin: 5px;padding: 0"
            @click="viewImages(item.images)"
            class="pointer"
        >
          <img
              style="object-fit: cover"
              alt="multiImage"
              :src="item.images[0] | image"
          />
        </v-avatar>
        <p
            v-if="item.images == null || item.images.length === 0"
            style="margin: 0"
        >
          No Image
        </p>
      </template>
      <template #keywords="{item}">
        {{ item.keywords | join }}
      </template>
      <template #createdAt="{item}">
        {{ item.createdAt | date('MM-DD-YYYY hh:mm a') }}
      </template>
      <template #primary-action>
        <v-btn @click="openUploadBusinesses"
               :width="$vuetify.breakpoint.mdAndUp ? 'fit-content' : '100%'"
               elevation="0"
               class="mr-2"
        >Import
        </v-btn>
      </template>
      <template #secondary-actions>
        <v-menu v-model="filter" offset-y min-width="200" :close-on-content-click="false" :close-on-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs"
                   v-on="on"
                   icon
                   style="margin-left: 10px">
              <v-icon>mdi-filter</v-icon>
            </v-btn>
          </template>
          <v-card class="pa-4">
            <p>Filter</p>
            {{ categoryId }}
            <v-select
                v-model="categoryId"
                :items="categories"
                :loading="loadingCategories"
                :rules="[required('A category must be selected')]"
                :value-comparator="(a, b) => a && b && a === b"
                class="span-2"
                dense
                item-text="name"
                item-value="_id"
                label="Select Category"
                outlined
            >
              <template v-slot:item="{item}">
                <div>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ item.name }}
                    </v-list-item-title>
                    <v-list-item-action-text class="text-wrap">
                      {{ item.description.length > 50 ? item.description.substring(0, 50) + '...' : item.description }}
                    </v-list-item-action-text>
                  </v-list-item-content>
                </div>
              </template>
            </v-select>
<!--            <v-text-field v-model="categoryId"  type="text" outlined label="Category ID" dense/>-->
            <v-text-field v-model="city"  type="text" outlined label="City" dense/>
            <v-select v-model="status" :items="statuses" outlined label="Status" dense></v-select>
            <v-select v-model="sortBy" :items="sortList" item-text="title" item-value="value" outlined
                      label="Sort By" dense></v-select>
            <v-select v-model="sortByDir" :items="sortByDirList" dense item-text="title" item-value="value"
                      label="Sort By" outlined></v-select>
            <div class="d-flex" style="gap:10px">
              <v-btn @click="clearFilter" elevation="0" class="flex-grow-1">
                Clear
              </v-btn>
              <v-btn @click="applyFilter" elevation="0" color="primary" class="flex-grow-1">
                Filter
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </template>
    </DataTable>

    <v-dialog v-model="statusForm" width="30%">
      <v-card class="pa-4" v-if="business">
        <p>Update Business Status</p>
        <v-select v-model="business.status" :items="statuses" outlined label="Status" dense></v-select>
        <div class="d-flex justify-end">
          <v-btn @click="updateBusiness" elevation="0" color="primary">
            Update
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog persistent width="50%" v-model="showCsvUploadModal">
      <v-card class="px-5 py-7">
        <div v-if="!isUploading">
          <v-card-title class="px-0">{{ isUploaded ? 'Businesses Imported' : 'Import Businesses By CSV' }}</v-card-title>
          <v-form ref="import">
            <div v-if="isUploaded">
              <div class="d-flex py-5">
                <v-chip class="mr-2" color="primary">Total: {{ this.businesses.length }}</v-chip>
                <v-chip class="mr-2" color="success">Completed: {{ this.added }}
                </v-chip>
                <v-chip class="mr-2" color="error">Rejected: {{ this.businesses.length - this.added }}</v-chip>
              </div>
              <div class="mb-2">
                <ul style="color: red" v-for="(error,i) of importErrors" :key="i">
                  <li>{{ error.text }} at entry # {{ error.item }}</li>
                </ul>
              </div>
              <div class="d-flex justify-end">
                <v-btn @click="cancelUpload" class="mr-2">OK</v-btn>
                <v-btn @click="newImport" class="ml-2" color="primary">New Import</v-btn>
              </div>
            </div>
            <div v-else>
<!--              <p>Download a <a href="#" download="business_template.csv">sample CSV template</a> to-->
<!--                see an example of the format required.</p>-->
              <v-file-input :rules="[required()]" v-model="uploadFile" outlined dense label="Upload CSV" prepend-icon=""
                            accept=".csv"
                            @change="bulkUpload"/>

              <v-select
                  v-model="categoryIdForImport"
                  :items="categories"
                  :loading="loadingCategories"
                  :rules="[required('A category must be selected')]"
                  :value-comparator="(a, b) => a && b && a === b"
                  class="span-2"
                  dense
                  item-text="name"
                  item-value="_id"
                  label="Select Category"
                  outlined
              >
                <template v-slot:item="{item}">
                  <div>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ item.name }}
                      </v-list-item-title>
                      <v-list-item-action-text class="text-wrap">
                        {{ item.description.length > 50 ? item.description.substring(0, 50) + '...' : item.description }}
                      </v-list-item-action-text>
                    </v-list-item-content>
                  </div>
                </template>
              </v-select>

              <v-combobox
                  v-model="keywords"
                  :rules="[requiredArray('At least one keyword must be provided')]"
                  :search-input.sync="searchKeywords"
                  append-icon=""
                  chips
                  class="span-2"
                  clearable
                  deletable-chips
                  dense
                  label="Keywords"
                  multiple
                  outlined
                  small-chips
              >
              </v-combobox>

              <div class="d-flex justify-end">
                <div>
                  <v-btn @click="cancelUpload" class="mr-2">Cancel</v-btn>
                  <v-btn @click="uploadBusinesses" class="ml-2" color="primary">Upload</v-btn>
                </div>
              </div>
            </div>
          </v-form>
        </div>
        <div v-else class="pa-16 d-flex justify-center align-center">
          <v-progress-circular indeterminate class="mr-3"/>
          <p class="ma-0">Importing Businesses...</p>
        </div>
      </v-card>
    </v-dialog>

    <image-viewer ref="imageViewer"/>
    <loading-dialogue ref="loading"/>
    <error-dialogue ref="error"/>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {BusinessService} from "../../services/business";
import {checkScope} from "../../utils/local";
import {required, requiredArray} from "../../utils/validators";
import LoadingDialogue from "../../components/dialogues/LoadingDialogue";
import Papa from 'papaparse'
import {CategoryService} from "../../services/category";

export default {
  name: "index",
  components: {LoadingDialogue, DataTable},
  data() {
    return {
      service: new BusinessService(),
      headers: [
        {
          text: 'Images',
          value: 'images',
          sortable: false
        },
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Keywords',
          value: 'keywords',
          sortable: false
        },
        {
          text: 'Address',
          value: 'address',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      statuses: [
        'Active',
        'Inactive',
        'Pending'
      ],
      statusForm: false,
      business: null,
      status: null,
      filter: false,
      city: null,
      sortBy: 'none',
      sortList: [
        {title: 'None', value: 'none'},
        {title: 'Name', value: 'name'},
        {title: 'Joining Date', value: 'createdAt'},
      ],
      sortByDir: 1,
      sortByDirList: [
        {title: 'Asc', value: 1},
        {title: 'Desc', value: -1},
      ],
      showCsvUploadModal: false,
      uploadFile: null,
      isUploaded: false,
      isUploading: false,
      businesses: [],
      importErrors: [],
      added: 0,
      categoryService: new CategoryService(),
      categories: [],
      loadingCategories: false,
      categoryId: null,
      categoryIdForImport: null,
      keywords: [],
      searchKeywords: '',
    }
  },
  watch: {
    searchKeywords(newVal) {
      this.setKeywords(newVal)
    }
  },
  async mounted() {
    await this.getCategories();
  },
  methods: {
    checkScope,
    required,
    requiredArray,
    setKeywords(val) {
      if (val) {
        if (val.includes(',')) {
          this.keywords.push(...(val.split(",")).filter((i) => i && i !== ''));
          this.keywords = this.keywords.filter((value, index, self) => {
            return self.indexOf(value) === index;
          })
          this.searchKeywords = "";
        }
      }
    },
    async getCategories() {
      this.loadingCategories = true
      this.categories = await this.categoryService.fetchAll()
      this.loadingCategories = false
    },
    async uploadBusinesses() {
      if (this.$refs.import.validate()) {
        this.added = 0
        this.isUploading = true
        this.isUploaded = false
        this.importErrors = []
        for (let i = 0; i < this.businesses.length; i++) {
          let item = this.businesses[i];
          let error = false
          const index = this.businesses.indexOf(item);
          const business = {}

          business.location = {
            type: 'Point',
            coordinates: []
          }

          console.log('categoryId', this.categoryIdForImport)
          item.business_name ? business.name = item.business_name : 'N/A';
          item.email ? business.email = item.email : 'N/A';
          item.categoryId ? business.categoryId = item.categoryId : business.categoryId = this.categoryIdForImport;
          item.keyword ? business.keywords = item.keyword.split(',').concat(this.keywords)  : business.keywords = this.keywords;
          item.city ? business.city = item.city : 'N/A';
          // item.state ? business.state = item.state : 'N/A';
          // item.country ? business.country = item.country : 'N/A';
          business.averageRating = 0;
          item.address ? business.address = item.address : 'N/A';
          item.website ? business.website = item.website : 'N/A';
          item.phone ? business.phone = item.phone : 'N/A';
          item.postal_code ? business.plus_code = item.postal_code : 'N/A';
          item.longitude ? business.location.coordinates[0] = item.longitude : 'N/A';
          item.latitude ? business.location.coordinates[1] = item.latitude : 'N/A';

          console.log(business)

          await this.service.create(business).then(() => {
            this.added += 1
            return true;
          }).catch((e) => {
            this.importErrors.push({
              item: index + 2,
              text: e
            })
          })

          // business.images = []
          // business.pdfs = []
          // business.videos = []
          // business.trial = item.trial.toLowerCase() === 'true';
          // business.for_passage = item.passage.toLowerCase() === 'true';
          // business.createdAt = new Date()
          if (!error) {

            // await this.service.create(business).then(() => {
            //   this.added += 1
            //   return true;
            // }).catch((e) => {
            //   this.importErrors.push({
            //     item: index + 2,
            //     text: e
            //   })
            // })
          }
        }
        this.isUploading = false
        this.isUploaded = true
      }
    },
    openUploadBusinesses() {
      this.showCsvUploadModal = true;
    },
    async load(page, limit, search) {
      return await this.service.fetchAll(page, limit, search, this.status, this.sortBy, this.sortByDir, this.categoryId, this.city)
    },
    cancelUpload() {
      if (this.isUploaded) {
        document.getElementById('refresh').click()
      }
      this.uploadFile = null
      this.isUploaded = false
      this.isUploading = false
      this.showCsvUploadModal = false
    },
    bulkUpload(item) {
      if (item) {
        const reader = new FileReader()
        reader.onload = e => {
          const data = e.target.result
          const result = Papa.parse(data.toString(), {
            header: true,
            transformHeader: function (header) {
              return header.toLowerCase()
            },
            skipEmptyLines: true
          })
          this.businesses = result.data
        }
        reader.readAsText(item)
      }
    },
    newImport() {
      this.businesses = []
      this.uploadFile = null
      this.importErrors = []
      this.isUploading = false
      this.isUploaded = false
    },
    add() {
      let routeData = this.$router.resolve({name: 'Business'});
      window.open(routeData.href, '_blank');

      // this.$router.push('/business')
    },
    view(item) {
      let routeData = this.$router.resolve('/business?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/business?id=' + item._id + '&view=true')
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'Business', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push('/business?id=' + item._id)
    },
    viewImages(images) {
      this.$refs.imageViewer.show({images})
    },
    applyFilter() {
      if (this.status !== null || this.sortBy !== null || this.sortByDir !== null || this.categoryId !== null || this.city !== null) {
        this.filter = false
        this.$refs.datatable.loadData()
      } else {
        this.$toast.error('No Filter Selected')
      }
    },
    clearFilter() {
      this.filter = false
      this.status = null
      this.$refs.datatable.loadData()
    },
    deleteItem(item) {
      if (confirm('Are you sure, you want to delete this permanently?')) {
        try {
          this.loading = true;
          this.service.delete(item);
          this.loading = false;
          document.getElementById('refresh').click();
        } catch (e) {
          window.console.log(e);
        }
      }
    },
    openForm(item) {
      this.business = item
      this.statusForm = true
    },
    async updateBusiness() {
      try {
        this.$refs.loading.show({message: 'Updating Business Status'})
        await this.service.update(this.business._id, this.business)
        this.$refs.loading.hide()
        this.statusForm = false
        await this.$refs.datatable.loadData()
      } catch (e) {
        let error
        if (e && e.data && e.data.message) {
          error = e.data.message
        } else {
          error = 'Some error occurred. Try again later'
        }
        this.$refs.error.show(
            {
              title: 'Error Updating Status',
              message: error,
              okButton: 'OK',
            }
        )
      }
    }
  }
}
</script>

<style scoped>

</style>