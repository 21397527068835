<template>
  <div>
    <DataTable
        title="Sub Admins"
        :headers="headers"
        :loader="load"
        :allow-add="checkScope(['sub-admins:new'])"
        @add-new="addNew"
        :show-actions="checkScope(['sub-admins:edit','sub-admins:detail', 'sub-admin:delete'])"
        :edit-handler="checkScope(['sub-admins:edit']) ? edit : null"
        :view-handler="checkScope(['sub-admins:detail']) ? view :  null"
        :delete-handler="checkScope(['sub-admins:delete']) ? service.delete :  null"
        :default-footer="false"
        pagination
        ref="datatable"
    >
      <template #scopes="{ item }">
        {{ item.scopes.join(', ').substr(0, 80) + '...' }}
      </template>
      <template #createdAt="{ item }">
        {{ item.createdAt | date('DD/MM/YYYY') }}
      </template>
    </DataTable>
    <confirm-dialogue ref="confirm"/>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";
import {PersonService} from "@/services/person";
import {checkScope} from "@/utils/local";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      headers: [
        {
          text: 'Display Name',
          value: 'name',
          sortable: false,
          width: 150
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          width: 200
        },
        {
          text: 'Scopes',
          value: 'scopes',
          sortable: false
        },
        {
          width: 180,
          text: 'Created At',
          value: 'createdAt'
        }
      ],
      service: new PersonService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAllAdmins(page, limit, search)
    },
    reloadData() {
      document.getElementById('refresh').click()
    },
    addNew() {
      let routeData = this.$router.resolve({name: 'SubAdmin'});
      window.open(routeData.href, '_blank');

      // this.$router.push('/sub-admin');
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'SubAdmin', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push(`/sub-admin?id=${item._id}`);
    },
    view(item) {
      let routeData = this.$router.resolve('/sub-admin?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/sub-admin?id=' + item._id + '&view=true')
    },
  }
}
</script>

<style scoped>

</style>