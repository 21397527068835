import Vue from 'vue';
import axios from "axios";

Vue.prototype.$axios = axios
// axios.defaults.baseURL = 'http://164.90.156.36:3000'
axios.defaults.baseURL = 'https://api-staging.4dost.com'
// axios.defaults.baseURL = ''

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            config.headers.common["Authorization"] = 'bearer ' + token;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        if (response.status === 200 || response.status === 201) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                case 401:
                    localStorage.removeItem('auth_token')
                    localStorage.removeItem('auth_user')

                    break;
            }
            return Promise.reject(error.response);
        }
    }
);