<template>
  <popup-modal ref="popup" width-l-g="90%" fullscreen>
    <div style="position:relative;">
      <div style="position:absolute; top: 5px; right: 5px;z-index: 1" class="pointer">
        <v-avatar @click="_confirm" class="elevation-1" size="35" color="white">
          <v-icon color="grey darken-2">mdi-close</v-icon>
        </v-avatar>
      </div>
      <Carousal :images="images" height="100vh"/>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'
import Carousal from "@/components/Carousal";

export default {
  name: 'ImageViewer',

  components: {Carousal, PopupModal},

  data: () => ({
    images: undefined,
  }),

  methods: {
    show(opts = {}) {
      this.images = opts.images
      this.$refs.popup.open()
    },

    _confirm() {
      this.$refs.popup.close()
    },

  },
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>