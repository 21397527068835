import Banners from "../../views/banners/index";
import Banner from "../../views/banners/form"
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const bannersRoutes = [
    {
        name: 'Banners',
        path: '/banners',
        component: checkScope(['banners:view']) ? Banners : PermissionRequired
    },
    {
        name: 'Banner',
        path: '/banner',
        component: checkScope(['banners:detail','banners:new','banners:edit']) ? Banner : PermissionRequired
    },
];

export const bannersRouter = bannersRoutes.filter(function (x) {
    return x !== null;
})