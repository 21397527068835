import axios from 'axios';

export class BusinessClaimService {
    async fetchAll(page = 0, limit = 20, search = '', status = '') {
        return (await axios.get('/business-claim?page=' + page + '&limit=' + limit + '&search=' + search + (status ? '&status=' + status : ''))).data;
    }

    async fetchOne(id) {
        return (await axios.get('/business-claim/' + id)).data;
    }

    async update(id, item) {
        return (await axios.patch('/business-claim/' + id, item)).data;
    }

    async create(item) {
        return (await axios.post('/business-claim', item)).data;
    }
    async delete(item) {
        return (await axios.delete('/business-claim/' + item._id)).data;
    }
}