<template>
  <div id="background">
    <v-card class="sign-in" elevation="10">
      <img src="../../assets/logo.png" alt="Logo" class="sign-in__logo"/>

      <h1 class="sign-in__title">Authenticate yourself</h1>
      <p class="sign-in__message">
        You need an admin account to access the content ahead, if you do not
        have an account or have forgotten your password then you can contact the
        support regarding your issue
      </p>
      <v-form ref="form">
        <v-text-field
            v-model="username"
            label="Your Email"
            outlined
            :rules="[required(), email()]"
        />
        <v-text-field
            v-model="password"
            :rules="[required('Do not leave this field empty')]"
            @keypress.enter="signIn"
            label="Your Password"
            :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
            outlined
        />

        <v-btn
            @click="signIn"
            color="primary"
            elevation="0"
            width="100%"
            height="45px"
            :disabled="loading"
        >
          {{ loading ? 'Authenticating' : 'Authenticate' }}
          <v-icon v-if="!loading" small style="margin-left: 5px">mdi-arrow-right</v-icon>
          <v-progress-circular v-else size="18" width="2" indeterminate style="margin-left: 5px"/>
        </v-btn>
      </v-form>
    </v-card>
    <errorDialogue ref="error"/>
  </div>
</template>

<script>
import {required, email} from '@/utils/validators';
import {AuthService} from "@/services/auth";

export default {
  name: 'SignIn',

  data: () => ({
    error: false,
    errorVal: [],
    loading: false,
    // username: 'admin@4dost.com',
    // password: '12345678',
    username: '',
    password: '',
    showPassword: false,
    service: new AuthService()
  }),

  methods: {
    email,
    required,
    async signIn() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.error = false
        this.errorVal = []
        try {
          const token = await this.service.signIn({
            username: this.username,
            password: this.password
          })
          localStorage.setItem('auth_token', token.access_token)
          const user = await this.service.getPerson()
          await localStorage.setItem('auth_user', JSON.stringify(user))
          if (user.role === 'Admin') {
            location.href = '/'
          } else {
            localStorage.clear()
            this.$refs.error.show(
                {
                  title: 'Error Authenticating',
                  message: 'You need an admin account to access content ahead.',
                  okButton: 'OK',
                }
            )
          }
          this.loading = false
        } catch (e) {
          this.loading = false;
          let error
          if (e && e.data && e.data.message) {
            error = e.data.message
          } else {
            error = 'Some error occured. Try again later'
          }
          this.$refs.error.show(
              {
                title: 'Error Authenticating',
                message: error,
                okButton: 'OK',
              }
          )
        }
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.sign-in
  width: 448px
  padding: 20px 40px 40px 40px
  background: white
  text-align: center
  border-radius: 8px

  &__header
    display: grid
    grid-column-gap: 20px
    grid-template-columns: calc(30% - 20px) 70%

  &__logo
    width: 30%
    margin-bottom: 20px

  &__title
    color: black
    font-size: 24px
    margin-bottom: 10px
    font-weight: normal
    font-family: google-sans, sans-serif

  &__message
    font-size: 13px
    margin-bottom: 40px


#background
  height: 100vh
  display: flex
  align-items: center
  justify-content: center
  background-color: #e5e5f7
  opacity: 0.8
  background: radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent), radial-gradient(circle, transparent 20%, #e5e5f7 20%, #e5e5f7 80%, transparent 80%, transparent) 25px 25px, linear-gradient(#ee6725 2px, transparent 2px) 0 -1px, linear-gradient(90deg, #ee6725 2px, #e5e5f7 2px) -1px 0
  background-size: 50px 50px, 50px 50px, 25px 25px, 25px 25px
</style>

<style>
html {
  overflow-y: auto;
}
</style>
