<template>
  <popup-modal ref="popup" is-persistent>
    <div style="padding: 20px; text-align: left; display: flex; align-items: center">
      <v-progress-circular indeterminate color="primary"/>
      <p style="margin-left: 20px; margin-bottom: 0;">{{ message }}</p>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
  name: 'LoadingDialogue',

  components: {PopupModal},

  data: () => ({
    message: 'Loading...',
  }),

  methods: {
    show(opts = {}) {
      if (opts.message)
        this.message = opts.message
      this.$refs.popup.open()
    },
    hide() {
      this.$refs.popup.close()
    }
  },
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>