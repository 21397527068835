<template>
  <div>
    <v-tabs v-model="tab" grow>
      <v-tab>All</v-tab>
      <v-tab>Pending</v-tab>
      <v-tab>Approved</v-tab>
      <v-tab>Rejected</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="elevation-1">
      <v-tab-item v-for="(item,i) in tabs" :key="i">
        <DataTable
            ref="datatable"
            @add-new="add"
            :title="item.title"
            :headers="headers"
            :loader="load"
            :allow-add="checkScope(['events:new'])"
            :show-actions="checkScope(['events:new','events:detail','events:edit','events:delete'])"
            :edit-handler="checkScope(['events:edit']) ? edit : null"
            :view-handler="checkScope(['events:detail']) ? view : null"
            :delete-handler="checkScope(['events:delete']) ? service.delete : null"
        >
          <template #cover="{item}">
            <v-avatar
                v-if="item && item.images.length > 0"
                style="margin: 5px;padding: 0"
            >
              <img
                  style="object-fit: cover"
                  alt="multiImage"
                  :src="item.images[0] | image"
              />
            </v-avatar>
            <p
                v-else
                style="margin: 0"
            >
              No Image
            </p>
          </template>
          <template #startDate="{item}">
            {{ getUtcDateTimeIntoLocal(item.startDateTime) }}
          </template>
          <template #endDate="{item}">
            {{ getUtcDateTimeIntoLocal(item.endDateTime) }}
          </template>
          <template #createdAt="{item}">
            {{ getUtcDateTimeIntoLocal(item.createdAt) }}
          </template>
          <template #extra-actions="{item}">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-if="(item.status === 'Pending' || item.status === 'Rejected') && tab !== 0 && checkScope(['events:status'])"
                    @click="updateStatus(item, 'Approved')"
                    color="white"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1 my-1"
                >
                  mdi-check
                </v-icon>
              </template>
              <span>Approve</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    small
                    v-if="(item.status === 'Pending' || item.status === 'Approved') && tab !== 0 && checkScope(['events:status'])"
                    @click="updateStatus(item, 'Rejected')"
                    color="white"
                    style="padding: 5px; border-radius: 6px; background: rgba(0,0,0,0.4)"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1 my-1"
                >
                  mdi-close
                </v-icon>
              </template>
              <span>Reject</span>
            </v-tooltip>
          </template>
          <template #secondary-actions>
            <v-menu v-model="filter" :close-on-click="false" :close-on-content-click="false" min-width="200" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon
                       style="margin-left: 10px"
                       v-bind="attrs"
                       v-on="on">
                  <v-icon>mdi-filter</v-icon>
                </v-btn>
              </template>
              <v-card class="pa-4">
                <p>Filter</p>
                <v-text-field v-model="city" dense label="City" outlined type="text"/>
                <v-select v-model="sortBy" :items="sortByList" dense item-text="title" item-value="value"
                          label="Sort By" outlined></v-select>
                <v-select v-model="sortByDir" :items="sortByDirList" dense item-text="title" item-value="value"
                          label="Sort By" outlined></v-select>
                <div class="d-flex" style="gap:10px">
                  <v-btn class="flex-grow-1" elevation="0" @click="clearFilter">
                    Clear
                  </v-btn>
                  <v-btn class="flex-grow-1" color="primary" elevation="0" @click="applyFilter">
                    Filter
                  </v-btn>
                </div>
              </v-card>
            </v-menu>
          </template>

        </DataTable>
      </v-tab-item>
    </v-tabs-items>
    <confirm-dialogue ref="confirm"/>
  </div>
</template>

<script>
import DataTable from "@/components/DataTable";
import {EventDealService} from "@/services/event-deal";
import {checkScope} from "@/utils/local";
import * as moment from "moment";

export default {
  name: "index",
  components: {DataTable},
  data() {
    return {
      tab: null,
      filter: false,
      city: null,
      sortBy: 'createdAt',
      sortByList: [
        {title: 'Created At', value: 'createdAt'},
        {title: 'Starting Date', value: 'startDateTime'},
      ],

      sortByDir: 1,
      sortByDirList: [
        {title: 'Asc', value: 1},
        {title: 'Desc', value: -1},
      ],
      headers: [
        {
          text: 'Cover',
          value: 'cover',
        },
        {
          text: 'Title',
          value: 'title',
        },
        {
          text: 'Description',
          value: 'description',
          width: '20%',
        },
        {
          text: 'Starting',
          value: 'startDate'
        },
        {
          text: 'Ending',
          value: 'endDate'
        },
        {
          text: 'Created At',
          value: 'createdAt'
        },
        {
          text: 'Status',
          value: 'status'
        },
      ],
      tabs: [
        {
          title: 'All Events',
          status: null,
        },
        {
          title: 'Pending Events',
          status: 'Pending'
        },
        {
          title: 'Approved Events',
          status: 'Approved'
        },
        {
          title: 'Rejected Events',
          status: 'Rejected'
        }
      ],
      service: new EventDealService()
    }
  },
  methods: {
    checkScope,
    load(page, limit, search) {
      return this.service.fetchAll(page, limit, search, this.tabs[this.tab].status, 'Event', this.city, this.sortBy, this.sortByDir)
    },
    getUtcDateTimeIntoLocal(_date) {
      return moment.utc(_date).local().format('MMMM DD, YYYY - hh:mm a');

    },
    add() {
      let routeData = this.$router.resolve({name: 'Event'});
      window.open(routeData.href, '_blank');

      // this.$router.push('/event')
    },
    view(item) {
      let routeData = this.$router.resolve('/event?id=' + item._id + '&view=true');
      window.open(routeData.href, '_blank');

      // this.$router.push('/event?id=' + item._id + '&view=true')
    },
    edit(item) {
      let routeData = this.$router.resolve({name: 'Event', query: {id: item._id}});
      window.open(routeData.href, '_blank');

      // this.$router.push('/event?id=' + item._id)
    },
    applyFilter() {
      if (this.city !== null || this.sortBy !== null || this.sortByDir !== null) {
        this.filter = false
        this.$refs.datatable[this.tab].loadData()
      } else {
        this.$toast.error('No Filter Selected')
      }
    },
    clearFilter() {
      this.filter = false
      this.$refs.datatable.loadData()
    },
    async updateStatus(item, status) {
      const confirm = await this.$refs.confirm.show({
        title: 'Warning',
        message: `This event will be ${status}. Do you want to proceed?`,
        okButton: 'Yes',
        cancelButton: 'No'
      });
      if (confirm) {
        const data = {
          status
        }
        try {
          this.$refs.datatable[0].loading = true
          await this.service.update(item._id, data)
          this.$refs.datatable[0].loading = false
          this.refresh()
        } catch (e) {
          this.$refs.datatable.loading = false
        }
      }
    },
    refresh() {
      const refresh = document.getElementsByClassName('refresh')
      for (let i = 0; i < refresh.length; i++) {
        refresh[i].click()
      }
    }
  }
}
</script>

<style scoped>

</style>