import axios from 'axios';

export class NotificationsService {
    async fetchAll(page = 0, limit = 20, search = '') {
        return (await axios.get(
                '/notification?page=' + page +
                '&limit=' + limit +
                '&search=' + search
            )
        ).data;
    }

    async delete(item) {
        return (await axios.delete('/notification/' + item._id)).data;
    }

    async create(item) {
        return (await axios.post('/notification', item)).data;
    }
}