<template>
  <SimpleForm ref="form" :onSubmit="submit" @done="clearForm">
    <p class="span-2 form__title">{{ isEdit ? 'Update Banner' : 'Add New Banner' }}</p>

    <v-autocomplete
        v-model="banner.dealId"
        :items="dealsOptions"
        :loading="loading"
        :rules="[required('Deal must be selected!')]"
        class="flex-grow-1 span-2"
        dense
        item-text="title"
        item-value="_id"
        label="Choose Deal"
        name="Deal"
        outlined
    />

    <div class="mb-3 span-2">
      <h3>Media</h3>

      <div class="mediaPrickerWrapper py-8 d-flex justify-center align-center my-5 flex-column"
           style="position: relative"
           @click="pickFile"
           @drop="dropHandler($event)"
           @dragenter.prevent
           @dragover.prevent
      >
        <v-avatar color="grey lighten-3 mb-2">
          <v-icon>mdi-upload</v-icon>
        </v-avatar>
        <p class="ma-0 text-center">Drag files here<br>
          <span class="font-weight-bold primary--text">Or select files to upload </span></p>
        <v-file-input
            id="mediaPicker"
            v-model="selectedMedia"
            :value="selectedMedia"
            accept="image/*, video/*"
            class="d-none"
            multiple
            @change="pickHandler"
        />
      </div>

      <div v-if="media.length > 0">
        <v-row class="span-2">
          <v-col v-for="(mediaItem, i) in media" :key="i"
                 :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                 class="file-display ma-2  pa-0"
                 cols="3"
                 no-gutters
                 style="position: relative;max-width: 130px !important;"
          >
            <div class="preview-image">
              <img :src="mediaItem.url" height="100%"
                   width="100%"/>
              <v-icon class="remove-icon" color="error" @click="removeMedia(mediaItem)">mdi-delete</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>


      <v-list>
        <v-list-item v-for="(error,i) in mediaErrors" :key="i">
          <v-list-item-avatar color="error lighten-4">
            <v-icon color="error">mdi-alert</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ error.title }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-icon v-if="error.type" :color="getSocialMediaTypeColor(error.type)" class="mr-2" small>
                {{ getSocialMediaTypeIcon(error.type) }}
              </v-icon>
              {{ error.subtitle }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {email, required, url} from '../../utils/validators';
import {BannersService} from "../../services/banners.js";
import axios from "axios";

async function uploadWithMessage(context, list, message) {
  context.showLoading(true, message)
  const newList = [];
  for (const item of list) {
    let formData = new FormData()
    formData.append('image', item.file);
    const response = await axios.post('/save-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }
    )
    newList.push(response.data.name)
    context.showLoading(true, message + ': ' + item.filenameWithoutExtension)
  }
  return newList;
}

export default {
  name: 'Form',
  components: {SimpleForm},

  data: () => ({
    loading: false,
    banner: {
      dealId: undefined,
      image: '',
      images: [],
    },
    dealsOptions: [],
    isEdit: false,
    errors: [],
    service: new BannersService(),

    // media picker
    media: [],
    mediaErrors: [],
    selectedMedia: [],
    uploadedFiles: 0,
  }),

  async mounted() {
    await this.loadBanners();
    await this.loadDeals();
  },

  methods: {
    required,
    email,
    url,
    async loadDeals() {
      this.$refs.form.showLoading(true, '')
      let _response = await this.service.getDeals();
      this.dealsOptions = _response.data;
      this.$refs.form.showLoading(false, '')

    },
    async loadBanners() {
      if (!this.$route.query.id) return;
      this.$refs.form.showLoading(true, 'Loading Banner Data...')
      this.isEdit = true;
      this.isView = !!this.$route.query.view
      this.banner = await this.service.fetchOne(this.$route.query.id)
      this.$refs.form.showLoading(false, '')
    },
    async submit(context) {
      try {
        if (this.media && this.media.length > 0) {
          if (this.banner.images && this.banner.images.length > 0) {
            this.banner.images = [
              ...this.banner.images,
              ...(await uploadWithMessage(this.$refs.form, this.media, 'Uploading Images'))
            ];
          } else {
            this.banner.images = [
              ...(await uploadWithMessage(this.$refs.form, this.media, 'Uploading Images'))
            ];
          }
          this.$refs.form.showLoading(false, '')
        }

      } catch (e) {
        context.reportError({
          title: 'Error occurred',
          description: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occurred. Try again later.',
        });
        return false
      }
      try {
        this.$refs.form.showLoading(true, 'Creating banner...')
        this.banner.image = this.banner.images[0]
        await this.service.create(this.banner)
        this.$refs.form.showLoading(false, '')
        this.$toast.success('Banner created successfully')
        await this.$router.push('/banners')
        return true
      } catch (e) {
        context.reportError({
          title: 'Error occurred while creating banner',
          description: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occurred. Try again later.',
        });
        return false
      }
    },
    clearForm() {
      this.media = []
      this.banner = {
        dealId: undefined,
        image: '',
        images: [],
      }
      this.loadBanners()
    },

    // media related methods
    removeMedia(media) {
      if (media.file) {
        const index = this.selectedMedia.indexOf(media.file)
        this.selectedMedia.splice(index, 1)
      }
      const indexMedia = this.media.indexOf(media)
      this.media.splice(indexMedia, 1)
      this.uploadedFiles = this.media.length
    },
    pickFile() {
      document.querySelector("#mediaPicker").click();
    },
    pickHandler() {
      this.uploadedFiles = 0
      this.handleMedia(this.selectedMedia)
      this.media = [...this.media]
    },
    async dropHandler(ev) {
      ev.preventDefault();
      this.uploadedFiles = 0
      await this.handleMedia(ev.dataTransfer.files)
      this.media = [...this.media]
    },
    async handleMedia(files) {
      // const allowedTypes = ['png', 'jpg', 'jpeg', 'gif','mp4'];
      for (let i = 0; i < files.length; i++) {
        if ((files[i].type.includes('image') && files[i].size < 5242880) || (files[i].type.includes('video') && files[i].size < 209715200)) {
          try {
            const fileData = {
              file: files[i],
              url: URL.createObjectURL(files[i]),
              uploaded: 0,
              isUploaded: false,
              name: files[i].name,
              type: files[i].type,
              size: files[i].size
            }
            fileData.uploaded = 100
            fileData.isUploaded = true
            this.media.push(fileData);
            this.uploadedFiles = this.media.length;
          } catch (e) {
            this.$toast.error('Some error occurred. ' + files[i].name + ' cannot be selected.')
          }
        } else {
          this.$toast.error(files[i].type.includes('image') ? files[i].name + ' is too large. Max size allowed is 5 Mb' : files[i].name + ' is too large. Max size allowed is 200 Mb')
        }
      }
    },
  },

};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
.mediaPrickerWrapper {
  border: 1px dashed #2177b0;
  border-radius: 8px;
}

.file-display {
  border: 1px solid #2177b0;
}

.contact-upload-dialog p, .contact-upload-dialog a {
  font-size: 14px;
}

.preview-image {
  width: 120px !important;
  height: 120px !important;
}

.remove-icon {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  cursor: pointer !important;
}
</style>