import Vue from 'vue';
import VueRouter from 'vue-router';
import SignInView from './views/auth/SignIn';
import NotFound from './views/404.vue';
import BaseLayout from './components/BaseLayout';
import Home from './views/home.vue'
import {usersRouter} from "./views/users/router";
import {businessesRouter} from "./views/businesses/router";
import {bannersRouter} from "./views/banners/router";
import {categoriesRouter} from "./views/categories/router";
import {eventsRouter} from "./views/events/router";
import {dealsRouter} from "./views/deals/router";
import {reviewsRouter} from "./views/reviews/router";
import {claimedBusinessesRouter} from "./views/claimed-businesses/router";
import {notificationsRouter} from "./views/notifications/router";
import {subAdminsRouter} from "./views/subAdmins/router";
import VerifyAccount from "@/views/auth/verifyAccount";
import ResetPassword from "@/views/auth/resetPassword";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: BaseLayout,
        children: [
            {
                path: '/',
                component: Home
            },
            ...usersRouter,
            ...businessesRouter,
            ...bannersRouter,
            ...categoriesRouter,
            ...eventsRouter,
            ...dealsRouter,
            ...reviewsRouter,
            ...claimedBusinessesRouter,
            ...notificationsRouter,
            ...subAdminsRouter
        ]
    },
    {
        name: 'SignIn',
        path: '/auth/sign-in',
        component: SignInView
    },
    {
        path: '/auth/verifyaccount/:hash/:id',
        name: 'VerifyAccount',
        props: true,
        component: VerifyAccount
    },
    {
        path: '/auth/resetpassword/:id',
        name: 'ResetPassword',
        props: true,
        component: ResetPassword,
    },
    {
        name: 'NotFound',
        path: '**',
        component: NotFound
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

// eslint-disable-next-line no-unused-vars
router.beforeEach((to, __, next) => {
    if (!localStorage.getItem('auth_token')) {
        if (to.path !== '/auth/sign-in' && !to.path.includes('/auth/verifyaccount') && !to.path.includes('/auth/resetpassword')) {
            next('/auth/sign-in');
        }
    }
    next();
});

export default router;
