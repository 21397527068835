var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DataTable',{attrs:{"title":"Reviews","headers":_vm.headers,"loader":_vm.load,"allow-add":false,"show-actions":_vm.checkScope(['reviews:edit', 'reviews:detail', 'reviews:delete']),"edit-handler":_vm.checkScope(['reviews:edit']) ? _vm.edit : null,"view-handler":_vm.checkScope(['reviews:detail']) ? _vm.view : null,"delete-handler":_vm.checkScope(['reviews:delete']) ? _vm.service.delete : null},scopedSlots:_vm._u([{key:"createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.createdAt,'MM-DD-YYYY hh:mm a'))+" ")]}},{key:"reply",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.reply && item.reply.replyText)+" ")]}},{key:"user",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.userId ? item.userId.name : '-')+" ")]}},{key:"business",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businessId ? item.businessId.name : '-')+" ")]}},{key:"rating",fn:function(ref){
var item = ref.item;
return [_c('v-rating',{attrs:{"value":item.rating,"dense":"","color":"amber","background-color":"amber","readonly":""}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }