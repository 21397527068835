import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';

import './plugins/axios'
import './plugins/phoneNumberField'
import './plugins/googlemaps'
import './plugins/toast'
import './plugins/global-filters'
import './plugins/global-components'
import './plugins/filePond'
import './plugins/chart'

Vue.config.productionTip = false;

import './assets/style.sass';

new Vue({
    vuetify,
    router,
    render: (h) => h(App)
}).$mount('#app');
