import Notifications from "../../views/notifications/index";
import Notification from "../../views/notifications/form";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const notificationsRoutes = [
    {
        name: 'Notifications',
        path: '/notifications',
        component: checkScope(['notifications:view']) ? Notifications : PermissionRequired
    },
    {
        name: 'Notification',
        path: '/notification',
        component: checkScope(['notifications:new']) ? Notification : PermissionRequired
    },
];

export const notificationsRouter = notificationsRoutes.filter(function (x) {
    return x !== null;
})