<template>
  <v-carousel :height="height" :show-arrows="images && images.length > 1" hide-delimiters>
    <v-carousel-item
        v-for="(image,i) in images"
        :key="i"
        :src="image | image"
    ></v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: 'Carousal',
  props: {
    images: {
      type: Array,
      default: () => []
    },
    height: {
      type: String,
      default: '100%'
    },
  },
}
</script>

<style scoped>
</style>