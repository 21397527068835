import Deals from "../../views/deals/index";
import Deal from "../../views/deals/form";
import {checkScope} from "../../utils/local";
import PermissionRequired from "../../views/permission-required";

const dealsRoutes = [
    {
        name: 'Deals',
        path: '/deals',
        component: checkScope(['deals:view']) ? Deals : PermissionRequired
    },
    {
        name: 'Deal',
        path: '/deal',
        component: checkScope(['deals:detail','deals:new','deals:edit']) ? Deal : PermissionRequired
    },
];

export const dealsRouter = dealsRoutes.filter(function (x) {
    return x !== null;
})