<template>
  <popup-modal ref="popup" is-persistent>
    <h2 style="margin-top: 0">{{ title }}</h2>
    <p>{{ message }}</p>
    <div class="d-flex justify-end" style="gap: 15px">
      <v-btn color="primary" outlined @click="_cancel">{{ cancelButton }}</v-btn>
      <v-btn color="primary" elevation="0" @click="_confirm">{{ okButton }}</v-btn>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from './PopupModal.vue'

export default {
  name: 'ConfirmDialogue',

  components: {PopupModal},

  data: () => ({
    title: 'Are you Sure?',
    message: 'This action can\'t be reverted',
    okButton: 'Confirm',
    cancelButton: 'Go Back',

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),

  methods: {
    show(opts = {}) {
      if (opts.title)
        this.title = opts.title
      if (opts.message)
        this.message = opts.message
      if (opts.okButton)
        this.okButton = opts.okButton
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton
      }
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open()
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    _confirm() {
      this.$refs.popup.close()
      this.resolvePromise(true)
    },

    _cancel() {
      this.$refs.popup.close()
      this.resolvePromise(false)
    },
  },
}
</script>

<style scoped>
.btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ok-btn {
  color: red;
  text-decoration: underline;
  line-height: 2.5rem;
  cursor: pointer;
}

.cancel-btn {
  padding: 0.5em 1em;
  background-color: #d5eae7;
  color: #35907f;
  border: 2px solid #0ec5a4;
  border-radius: 5px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;
}
</style>