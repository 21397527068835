<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView" :reset-allowed="false">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update Category' : 'Add New Category' }}</p>
    <p v-else class="span-2 form__title">Category Detail</p>

    <v-text-field
        v-model="category.name"
        dense
        :readonly="isView"
        :rules="[required('A category name must be provided')]"
        label="Name"
        outlined
        class="span-2"
    />
    <v-textarea
        v-model="category.description"
        dense
        :readonly="isView"
        :rules="[required('A category description must be provided')]"
        label="Description"
        outlined
        class="span-2"
    />
    <v-row class="span-2" v-if="category">
      <v-col cols="12" md="6">
        <v-text-field
            :value="category.createdAt | date('MM-DD-YYYY hh:mm a')"
            dense
            readonly
            label="Created At"
            outlined
            class="span-2"
        />
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
            :value="category.updatedAt | date('MM-DD-YYYY hh:mm a')"
            dense
            readonly
            label="Updated At"
            outlined
            class="span-2"
        />
      </v-col>
    </v-row>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {required} from '@/utils/validators';
import {CategoryService} from "@/services/category";

export default {
  name: 'Form',
  components: {SimpleForm},

  data: () => ({
    isEdit: false,
    isView: false,
    loading: false,
    category: {
      name: undefined,
      description: undefined,
    },
    errors: [],
    service: new CategoryService()
  }),

  mounted() {
    this.loadCategory();
  },

  methods: {
    required,
    async loadCategory() {
      try {
        if (!this.$route.query.id) {
          this.$router.back()
          return;
        }
        this.isEdit = true;
        this.isView = !!this.$route.query.view
        this.$refs.form.showLoading(true, 'Loading Category Data...')
        this.category = await this.service.fetchOne(this.$route.query.id)
        this.$refs.form.showLoading(false, '')
      } catch (e) {
        this.$refs.form.showLoading(false, '')
        this.$refs.form.reportError({
          title: 'Error getting category',
          message: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occured. Try again later.',
        })
      }
    },
    preCheck(context) {
      if (!context.validate()) {
        return false
      }
      return true;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        if (this.isEdit) {
          try {
            this.$refs.form.showLoading(true, 'Updating category...')
            this.category = await this.service.update(this.category._id, this.category)
            this.$refs.form.showLoading(false, '')
            this.$toast.success('Category updated successfully')
            return true
          } catch (e) {
            context.reportError({
              title: 'Error occurred while updating category',
              message: e && e.data && e.data.message ? e.data.message : e.toString() || 'Some error occured. Try again later.'
            });
            return false
          }
        } else {
          context.reportError({
            title: 'Can\'t create category.',
            description: 'Categories can only be updated.',
            okButton: 'OK'
          });
          return false
        }
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}
</style>