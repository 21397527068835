<template>
  <SimpleForm ref="form" :onSubmit="submit" :disabled="isView" @done="clearForm">
    <p v-if="!isView" class="span-2 form__title">{{ isEdit ? 'Update Notification' : 'Add New Notification' }}</p>
    <p v-else class="span-2 form__title">Notification Detail</p>

    <v-text-field
        v-model="notification.title"
        dense
        :readonly="isView"
        :rules="[required('A notification title must be provided')]"
        label="Title"
        outlined
        class="span-2"
    />

    <v-textarea
        v-model="notification.description"
        dense
        :readonly="isView"
        :rules="[required('An notification description must be provided')]"
        label="Description"
        outlined
        class="span-2"
    />

    <div class="mb-3 span-2">
      <h3>Media</h3>

      <div class="mediaPrickerWrapper py-8 d-flex justify-center align-center my-5 flex-column"
           style="position: relative"
           @click="pickFile"
           @drop="dropHandler($event)"
           @dragenter.prevent
           @dragover.prevent
      >
        <v-avatar color="grey lighten-3 mb-2">
          <v-icon>mdi-upload</v-icon>
        </v-avatar>
        <p class="ma-0 text-center">Drag files here<br>
          <span class="font-weight-bold primary--text">Or select files to upload </span></p>
        <v-file-input
            id="mediaPicker"
            v-model="selectedMedia"
            :value="selectedMedia"
            accept="image/*, video/*"
            class="d-none"
            multiple
            @change="pickHandler"
        />
      </div>

      <div v-if="media.length > 0">
        <v-row class="span-2">
          <v-col v-for="(mediaItem, i) in media" :key="i"
                 :style="mediaErrors.length > 0 ? 'border-color: red' : ''"
                 class="file-display ma-2  pa-0"
                 cols="3"
                 no-gutters
                 style="position: relative;max-width: 130px !important;"
          >
            <div class="preview-image">
              <img :src="mediaItem.url" height="100%"
                   width="100%"/>
              <v-icon class="remove-icon" color="error" @click="removeMedia(mediaItem)">mdi-delete</v-icon>
            </div>
          </v-col>
        </v-row>
      </div>

    </div>

    <confirm-dialogue ref="confirm"/>
  </SimpleForm>
</template>

<script>
import SimpleForm from '../../components/Form';
import {required} from '../../utils/validators';
import {NotificationsService} from "../../services/notifications";
import axios from "axios";

async function uploadWithMessage(context, list, message) {
  context.showLoading(true, message)
  const newList = [];
  for (const item of list) {
    let formData = new FormData()
    formData.append('image', item.file);
    const response = await axios.post('/save-image',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
        }
    )
    newList.push(response.data.name)
    context.showLoading(true, message)
  }
  return newList;
}

export default {
  name: 'Form',
  components: {SimpleForm},

  data: () => ({
    isEdit: false,
    isView: false,
    loading: false,
    notification: {
      title: undefined,
      description: undefined,
      image: '',
      images: [],
    },
    errors: [],
    service: new NotificationsService(),

    // media picker
    media: [],
    mediaErrors: [],
    selectedMedia: [],
    uploadedFiles: 0,
  }),

  methods: {
    required,
    preCheck(context) {
      let error = false
      if (!context.validate()) {
        error = true
      }
      return !error;
    },
    async submit(context) {
      if (this.preCheck(context)) {
        try {
          if (this.media && this.media.length > 0) {
            this.notification.image = (await uploadWithMessage(this.$refs.form, this.media, 'Uploading Image'))[0];
            this.$refs.form.showLoading(false, '')
          }
          this.$refs.form.showLoading(true, 'Creating notification...')
          await this.service.create(this.notification)
          this.$refs.form.showLoading(false, '')
          this.$toast.success('Notification created successfully')
          this.$router.push('/notifications');
          return true
        } catch (e) {
          context.reportError({
            title: 'Error occurred while creating notification',
            description: e.toString(),
            okButton: 'OK'
          });
          return false
        }
      }
    },
    clearForm() {
      this.notification = {
        title: '',
        description: '',
        image: '',
        images: [],
      }
    },

    // media related methods
    removeMedia(media) {
      if (media.file) {
        const index = this.selectedMedia.indexOf(media.file)
        this.selectedMedia.splice(index, 1)
      }
      const indexMedia = this.media.indexOf(media)
      this.media.splice(indexMedia, 1)
      this.uploadedFiles = this.media.length
    },
    pickFile() {
      document.querySelector("#mediaPicker").click();
    },
    pickHandler() {
      this.uploadedFiles = 0
      this.handleMedia(this.selectedMedia)
      this.media = [...this.media]
    },
    async dropHandler(ev) {
      ev.preventDefault();
      this.uploadedFiles = 0
      await this.handleMedia(ev.dataTransfer.files)
      this.media = [...this.media]
    },
    async handleMedia(files) {
      // const allowedTypes = ['png', 'jpg', 'jpeg', 'gif','mp4'];
      for (let i = 0; i < files.length; i++) {
        if ((files[i].type.includes('image') && files[i].size < 5242880) || (files[i].type.includes('video') && files[i].size < 209715200)) {
          try {
            const fileData = {
              file: files[i],
              url: URL.createObjectURL(files[i]),
              uploaded: 0,
              isUploaded: false,
              name: files[i].name,
              type: files[i].type,
              size: files[i].size
            }
            fileData.uploaded = 100
            fileData.isUploaded = true
            this.media.push(fileData);
            this.uploadedFiles = this.media.length
          } catch (e) {
            this.$toast.error('Some error occurred. ' + files[i].name + ' cannot be selected.')
          }
        } else {
          this.$toast.error(files[i].type.includes('image') ? files[i].name + ' is too large. Max size allowed is 5 Mb' : files[i].name + ' is too large. Max size allowed is 200 Mb')
        }
      }
    },
  }
};
</script>

<style scoped>
p {
  font-weight: bold;
  text-align: left;
}

.mediaPrickerWrapper {
  border: 1px dashed #2177b0;
  border-radius: 8px;
}

.file-display {
  border: 1px solid #2177b0;
}

.contact-upload-dialog p, .contact-upload-dialog a {
  font-size: 14px;
}

.preview-image {
  width: 120px !important;
  height: 120px !important;
}

.remove-icon {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  cursor: pointer !important;
}
</style>