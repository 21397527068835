<template>
  <div>
    <DataTable
        title="Banners"
        ref="datatable"
        @add-new="add"
        :headers="headers"
        :loader="load"
        :allow-add="checkScope(['banners:new'])"
        :delete-handler="checkScope(['claimed-businesses:delete']) ? service.delete : null"
    >
      <template #dealId="{item}">
        <span v-if="item && item.dealId">
          {{ checkDeal(item.dealId) }}
        </span>
        <span v-else>N/A</span>
      </template>

      <template #image="{item}">
        <v-avatar v-if="item.image" class="my-1 pointer" @click="preview(item)">
          <img :src="item.image | image"/>
        </v-avatar>
      </template>

      <template #createdAt="{item}">
        {{ item.createdAt | date('MM-DD-YYYY hh:mm a') }}
      </template>
    </DataTable>

    <ImageViewer ref="imageViewer" />
    <loading-dialogue ref="loading"/>
    <error-dialogue ref="error"/>
  </div>
</template>

<script>
import DataTable from "../../components/DataTable";
import {BannersService} from "../../services/banners";
import {checkScope} from "../../utils/local";
import {required} from "../../utils/validators";
import ImageViewer from "../../components/dialogues/ImageViewer";
import LoadingDialogue from "../../components/dialogues/LoadingDialogue";

export default {
  name: "index",
  components: {LoadingDialogue, DataTable, ImageViewer},
  data() {
    return {
      headers: [
        {
          text: 'Images',
          value: 'image',
          sortable: false
        },
        {
          text: 'Deal',
          value: 'dealId',
        },
      ],
      deals: [],
      service: new BannersService(),
    }
  },
  async mounted() {

    let _response = await this.service.getDeals();
    this.deals = _response.data;
  },
  methods: {
    checkScope,
    required,
    checkDeal(id) {
      return this.deals && this.deals.length > 0 ? this.deals.filter(deal => deal._id === id)[0].title : [];
    },
    async load(page, limit) {
      return await this.service.fetchAll(page, limit);
    },
    add() {
      this.$router.push('/banner')
    },
    preview(item) {
      this.$refs.imageViewer.show({ images: [item.image] })
    },
  }
}
</script>

<style scoped>

</style>