<template>
  <div>
    <v-app-bar app color="primary" dark elevate-on-scroll>
      <v-app-bar-nav-icon @click="drawer = !drawer"/>

      <v-card-title>4 Dost Admin</v-card-title>
      <v-spacer/>

      <profile-popup/>
    </v-app-bar>
    <v-main class="main-bg">
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
    <v-navigation-drawer v-model="drawer" app mobile-breakpoint="xs">
      <img src="../assets/logo.png" alt="logo" class="logo" style="width: 100px;height: 100px"/>
      <v-divider/>
      <template v-for="(route, key) in routes">
        <v-list-item
            v-if="route.isVisible"
            :key="key"
            class="route"
            color="#ee6725"
            active-class="route--active"
            dense
            :to="route.to"
        >
          <v-list-item-icon>
            <v-icon v-text="route.icon"/>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="route.title"/>
          </v-list-item-content>
        </v-list-item>
        <v-divider v-else-if="route.isDivider" :key="key" style="margin: 10px 20px"/>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import ProfilePopup from "./ProfilePopup";


export default {
  name: 'BaseLayout',
  components: {ProfilePopup},
  data: () => ({
    drawer: false,
  }),
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndUp
  },
  computed: {
    routes() {
      return [
        {
          to: '/',
          title: 'Dashboard',
          icon: 'mdi-view-dashboard-variant',
          isVisible: true
        },
        {isDivider: true},
        {
          to: '/users',
          title: 'Users',
          icon: 'mdi-account-group',
          isVisible: true
        },
        {
          to: '/banners',
          title: 'Banners',
          icon: 'mdi-account-box-multiple',
          isVisible: true,
        },
        {
          to: '/businesses',
          title: 'Businesses',
          icon: 'mdi-google-my-business',
          isVisible: true,
        },
        {
          to: '/claimed-businesses',
          title: 'Claimed Businesses',
          icon: 'mdi-google-my-business',
          isVisible: true
        },
        {
          to: '/categories',
          title: 'Categories',
          icon: 'mdi-shape',
          isVisible: true,
        },
        {
          to: '/deals',
          title: 'Deals',
          icon: 'mdi-handshake',
          isVisible: true,
        },
        {
          to: '/events',
          title: 'Events',
          icon: 'mdi-calendar',
          isVisible: true,
        },
        {
          to: '/reviews',
          title: 'Reviews',
          icon: 'mdi-star',
          isVisible: true,
        },
        {isDivider: true},
        {
          to: '/notifications',
          title: 'Notifications',
          icon: 'mdi-bell',
          isVisible: true,
        },
        {
          to: '/sub-admins',
          title: 'Sub Admins',
          icon: 'mdi-account-multiple',
          isVisible: true,
        },
      ];
    },
  }
};
</script>

<style lang="sass" scoped>
.logo
  display: block
  margin: 5px auto
  border-radius: 10px

.route
  margin: 5px
  text-align: left
  overflow: hidden
  border-radius: 4px
  font-size: 20px !important
  font-family: "Roboto", sans-serif

  i
    font-size: 20px

  &--active i
    color: inherit

  &--active
    color: #ee6725

.main-bg
  background: linear-gradient(to bottom, #ee6725 400px, white 400px)
</style>
